import React from "react";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import LinesPhone from "../../../images/lines-phone.png";
import "./style.css";
import "../../landing.css";
import localeText from "../../../../locale";

const LandingHeader = (props) => {
  return (
    <section className="background-header full-width">
      <div className="flex align-items-center justify-content-between py-8  ">
        <div className="flex flex-column text-white gap-6" style={{ maxWidth: "500px" }}>
          <div className="font-bold text-responsive-6_5xl title" style={{ lineHeight: "1.1em" }}>
            {localeText("revolutionizeWithExtraconomy")}
          </div>
          <div className="text-lg md:text-lg line-height-2">
            {localeText("revolutionizeWithExtraconomyDescription")}
          </div>
          <div>
            <Button
              onClick={() => props.scrollToSection("plans")}
              label={localeText("iWantToStart")}
              className="font-bold border-round-md border-none p-button-no-outline w-full md:w-auto px-4 py-4 md:px-7 md:py-3"
              style={{ backgroundColor: "#FFC839", color: "#204C21" }}
            />
          </div>
        </div>
        <div className="hidden lg:flex phone-lines">
          <img style={{ width: "100%", maxWidth: "333px" }} src={LinesPhone} alt="LinesPhone" />
          <div className="text-white p-3 hidden lg:inline border-round-lg card-blur-1">
            <div className="text-2xl font-bold line-height-3 title" style={{ color: "#433AC8" }}>
              € 1.300,00
            </div>
            <div className="opacity-70 text-xs">{localeText("extrapayBalance")}</div>
            <hr className="py-0 my-2 opacity-70" style={{ borderTop: "solid 1px #ffffff" }} />
            <div className="opacity-70 text-xs">
              {localeText("viewCashback")} <i className="pi pi-chevron-right text-sm"></i>
            </div>
          </div>
          <div className="text-white p-3 hidden lg:flex items-center gap-3 border-round-lg card-blur-2">
            <div className="flex align-items-center">
              <Avatar
                label="LR"
                size="medium"
                className="border-circle font-medium"
                style={{ backgroundColor: "#ACE6B8", color: "#204C21" }}
              />
            </div>
            <div>
              <div className="text-sm font-bold line-height-3" style={{ color: "#344563" }}>
                Luca Rossi
              </div>
              <div className="opacity-70 text-xs" style={{ color: "#204C21" }}>
                {localeText("partnerPlan")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingHeader;
