const extracoinCryptoIT = {
  confirmTransctionExtracoin: "Conferma il trasferimento utilizzando Extracoin",
  youDoNotHaveWalletLogged: "Non hai un portafoglio connesso",
  walletLogout: "Hai disconnesso il tuo portafoglio",
  walletLogin: "Hai connesso il tuo portafoglio",
  walletLoginFailed: "Errore durante la connessione del portafoglio",
  sendTransaction: "Invia transazione",
  destinyWallet: "Portafoglio del destino",
  EXCQuantity: "Quantità Extracoin",
  refreshAccount: "Aggiorna account",
};

export default extracoinCryptoIT;
