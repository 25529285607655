import React from "react";
import logoWhite from "../../../images/logo-white-horizontal.png";
import localeText from "../../../../locale";
import { Button } from "primereact/button";

const LandingNav = (props) => {
  return (
    <div className="text-white font-semibold p-4 md:px-8 gap-7 flex align-items-center md:justify-content-center">
      <img
        src={logoWhite}
        alt="app-logo"
        style={{ width: "100%", maxWidth: "195px", minWidth: "166px" }}
      />
      <div className=" gap-5 hidden md:flex align-items-center vertical-align-baseline" style={{ minHeight: "50px" }}>
        <Button
          className="font-semibold text-white hover:underline p-0 focus:shadow-none "
          link
          onClick={() => props.scrollToSection("about")}
        >
          {localeText("benefits")}
        </Button>
        <Button
          className="font-semibold text-white hover:underline p-0 focus:shadow-none "
          link
          onClick={() => props.scrollToSection("resources")}
        >
          {localeText("resources")}
        </Button>
        <Button
          className="font-semibold text-white hover:underline p-0 focus:shadow-none "
          link
          onClick={() => props.scrollToSection("plans")}
        >
          {localeText("plans")}
        </Button>
        <a
          href="https://extraconomy.blog/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold text-white hover:underline"
        >
          {localeText("blog")}
        </a>
      </div>
    </div>
  );
};

export default LandingNav;
