const statementTableDictEN = {
  walletStatement: "Wallet statement",
  extracoinStatement: "Extracredit statement",
  extrabonusStatement: "Extrabonus Bonus Ads",
  bonusStatement: "Daily lifetime income statement",
  from: "From",
  toFrom: "To",
  check: "Check",
  statement: "Statement",
  description: "Description",
  status: "Status",
  dateTransaction: "Transaction Date",
  emptyDates: "Please choose a period of two dates.",
  completed: "Completed",
  pending: "Pending",
  pendingVoucher: "Pending voucher",
  canceled: "Canceled",
};

export default statementTableDictEN;
