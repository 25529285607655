import React from "react";
import alertPhoneIcon from "../../../images/landing/alert-phone-icon.svg";
import alertChatIcon from "../../../images/landing/alert-chat-icon.svg";
import localeText from "../../../../locale";
import LandingPlansCards from "../LandingPlansCards/LandingPlansCards";

function LandingPlans() {
  const featuresCard1 = [
    localeText("featureShowcase"),
    localeText("featureEconomicNetwork"),
    localeText("noMonthlyPayments"),
  ];

  const featuresCard2 = [
    localeText("featureEcommerce"),
    localeText("featureDropshiping"),
    localeText("featureEconomicNetwork"),
    localeText("noMonthlyPayments"),
  ];

  const featuresCard3 = [
    localeText("featureOnlineStore"),
    localeText("featureGeolocation"),
    localeText("featureEvrythingIncluded"),
    localeText("noMonthlyPayments"),
  ];

  return (
    <section className="bg-white md:py-8" id="plans">
      <div className="grid py-4 mb-3">
        <div className="flex flex-column gap-3 col-12 md:col">
          <div className="font-medium text-sm mb-3" style={{ color: "#7A869A" }}>
            {localeText("plansAdvantages")}
          </div>
          <div className="font-bold text-5xl line-height-2 title" style={{ color: "#208C56" }}>
            {localeText("chooseYourPlanTitle")}
          </div>
          <div className="text-lg" style={{ color: "#505F79" }}>
            {localeText("chooseYourPlanDescription")}
          </div>
        </div>
        <div className="col hidden md:block align-self-center ">
          <div className="flex gap-3 justify-content-end w-full">
            <div>
              <img src={alertPhoneIcon} alt="landing-plans" />
            </div>
            <div>
              <div className="font-medium" style={{ color: "#28AB69" }}>
                {localeText("noMonthlyFees")}
              </div>
              <div className="text-sm mt-2" style={{ color: "#7A869A", maxWidth: "225px" }}>
                {localeText("noMonthlyPaymentsDesc")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingPlansCards
        featuresCard1={featuresCard1}
        featuresCard2={featuresCard2}
        featuresCard3={featuresCard3}
      />
      <div className="flex gap-4 pt-6 md:pb-4 pb-8" style={{ maxWidth: "410px" }}>
        <div>
          <img src={alertChatIcon} alt="AlertChatIcon" />
        </div>

        <div className="flex flex-column gap-2">
          <div className="font-bold text-lg" style={{ color: "#39873A" }}>
            {localeText("doubtsAboutPlans")}
          </div>
          <div className="text-sm" style={{ color: "#505F79" }}>
            {localeText("doubtsAboutPlansDesc")}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingPlans;
