import React, { useEffect, useState } from "react";

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "./styles.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import GetUserInfoService from "../../service/GetUserInfoService";
import localeText from "../../locale";
import { Button } from "primereact/button";
import { useUserContext } from "../../contexts/UserContext/UserContext";
import L from "leaflet";
import PinPoint from "./pinpoint.png";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryInstance } from "../../utilities/Cloudinary/cloudinaryInstance";
import { fill } from "@cloudinary/url-gen/actions/resize";
import ExternalLinkIcon from "../ExternalLinkIcon/ExternalLinkIcon";
import { Link } from "react-router-dom";

const markerIcon = new L.Icon({
  iconUrl: PinPoint,
  iconSize: [28, 28],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const ChangeMapCenter = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center && center.lat && center.lng) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};

const MapLeaflet = ({ style }) => {
  const { userInfo } = useUserContext();
  const { showErrorToast } = useToastContext();
  const [loadingStores, setLoadingStores] = useState(false);
  const [loadingMyLocation, setLoadingMyLocation] = useState(false);
  const [center, setCenter] = useState({ lat: 41.9, lng: 12.5 });
  const ZOOM_LEVEL = 14;
  const [cep, setCep] = useState("");
  const [selectedDistance, setSelectedDistance] = useState(null);
  const [stores, setStores] = useState([]);

  const distances = [
    { name: "5Km", distance: 5 },
    { name: "10Km", distance: 10 },
    { name: "20Km", distance: 20 },
    { name: "50Km", distance: 50 },
    { name: "100Km", distance: 100 },
    { name: localeText("allText"), distance: 999999999999999 },
  ];

  const userInfoService = new GetUserInfoService();

  const handleChangeCep = async (e) => {
    setCep(e?.target?.value?.replace(/\D/g, ""));
  };

  const searchStores = async () => {
    try {
      if (!cep || !selectedDistance) {
        showErrorToast({ detail: "fillAllFields" });
      }
      setLoadingStores(true);
      /* await searchMyCep(cep); */
      const res = await userInfoService.searchShopsLocation({
        myCep: cep,
        distance: selectedDistance?.distance,
      });
      if (res?.result?.length === 0) {
        showErrorToast({ detail: "noStoresFound" });
        return;
      }

      const stores = res.result;
      const storesWithCoordinates = [];

      for (const store of stores) {
        if (!store?.latitude && !store?.longitude) {
          if (!store?.user?.postalCode) continue; // Ignora esta loja

          try {
            const resStoreLocation = await userInfoService.searchMyLocation({
              myCep: store?.user?.postalCode,
            });

            if (!resStoreLocation?.result) continue; // Ignora esta loja

            if (resStoreLocation?.result) {
              store.latitude = resStoreLocation?.result?.latitude;
              store.longitude = resStoreLocation?.result?.longitude;
            }
          } catch (e) {
            console.log(e);
            continue; // Ignora esta loja
          }
        }

        storesWithCoordinates.push(store);
      }

      const formattedStoresWithUniqueCoordinates = [];

      for (const store of storesWithCoordinates) {
        let latitude = store.latitude;
        let longitude = store.longitude;

        // Ajusta as coordenadas enquanto houver duplicatas
        while (
          formattedStoresWithUniqueCoordinates.some(
            (s) => s.latitude === latitude && s.longitude === longitude
          )
        ) {
          // faça a variação de latitude e longitude de forma aleatória para mais ou para menos 0.02
          latitude += 0.002; // Incremento pequeno na latitude
          longitude += 0.002; // Incremento pequeno na longitude
        }

        // Adiciona o store com coordenadas únicas
        formattedStoresWithUniqueCoordinates.push({
          ...store,
          latitude,
          longitude,
        });
      }

      setStores(formattedStoresWithUniqueCoordinates);
    } finally {
      setLoadingStores(false);
    }
  };

  useEffect(() => {
    const infoService = new GetUserInfoService();
    const searchMyCep = async (cep) => {
      try {
        if (!cep) {
          return;
        }
        setLoadingMyLocation(true);
        const res = await infoService.searchMyLocation({
          myCep: cep,
        });
        setCenter({ lat: res?.result?.latitude, lng: res?.result?.longitude });
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingMyLocation(false);
      }
    };
    if (userInfo?.result?.user?.postalCode) {
      setCep(userInfo?.result?.user?.postalCode);
      searchMyCep(userInfo?.result?.user?.postalCode);
    }
  }, [userInfo?.result?.user?.postalCode]);

  return (
    <div>
      <div className="flex justify-content-center align-items-center">
        <MapContainer style={style} center={center} zoom={ZOOM_LEVEL} scrollWheelZoom={false}>
          <ChangeMapCenter center={center} />

          <div className="map-search-stores">
            <InputText
              value={cep}
              onChange={handleChangeCep}
              placeholder={localeText("zipCode")}
              name="cep"
              style={{ width: "80px" }}
            />
            <Dropdown
              value={selectedDistance}
              onChange={(e) => setSelectedDistance(e.value)}
              options={distances}
              optionLabel="name"
              placeholder={localeText("distance")}
              className="w-full md:w-14rem"
              style={{ width: "80px" }}
            />
            <Button
              onClick={searchStores}
              size="small"
              icon="pi pi-search"
              disabled={loadingStores || loadingMyLocation}
              loading={loadingStores || loadingMyLocation}
            />
          </div>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {stores &&
            stores?.length > 0 &&
            stores.map((store, index) => (
              <Marker
                key={index}
                icon={markerIcon}
                position={[store?.latitude, store?.longitude]}
                style={{ zIndex: 999 }}
              >
                <Popup style={{ maxWidth: "220px" }}>
                  <Link
                    to={`/store?user=${store?.user?.username}`}
                    className="font-bold inline-block lg:text-lg text-base text-gray-800 hover:text-green-600"
                  >
                    <AdvancedImage
                      cldImg={cloudinaryInstance
                        .image(store?.imagelogoid)
                        .format("webp")
                        .resize(fill())}
                      className="border-1 border-300 w-full"
                      style={{ minWidth: "220px", height: "auto", objectFit: "cover" }}
                    />

                    <p className="text-base m-0">{store?.title}</p>
                    <p className="text-xs m-0 mb-1" style={{ maxWidth: "220px" }}>
                      {store?.user?.address ? `${store?.user?.address},` : ""}{" "}
                      {store?.user?.number ? `${store?.user?.number} -` : ""} {store?.user?.state}{" "}
                      {store?.user?.city ? `${store?.user?.city},` : ""} {store?.user?.country}
                    </p>
                  </Link>
                  <div>
                    <div className="flex justify-content-start align-items-center gap-2">
                      {store?.facebook && (
                        <ExternalLinkIcon
                          href={`https://facebook.com/${store?.facebook}`}
                          iconClassNames={"pi-facebook"}
                          color="text-black-alpha-70 hover:text-green-600"
                          width="text-base"
                          target="_blank"
                        />
                      )}

                      {store?.instagram && (
                        <ExternalLinkIcon
                          href={`https://instagram.com/${store?.instagram}`}
                          iconClassNames={"pi-instagram"}
                          color="text-black-alpha-70 hover:text-green-600"
                          width="text-base"
                          target="_blank"
                        />
                      )}

                      {store?.tiktok && (
                        <ExternalLinkIcon
                          href={`https://tiktok.com/@${store?.tiktok}`}
                          iconClassNames={"pi-tiktok"}
                          color="text-black-alpha-70 hover:text-green-600"
                          width="text-base"
                          target="_blank"
                        />
                      )}

                      {store?.linkedin && (
                        <ExternalLinkIcon
                          href={`https://linkedin.com/${store?.linkedin}`}
                          iconClassNames={"pi-linkedin"}
                          color="text-black-alpha-70 hover:text-green-600"
                          width="text-base"
                          target="_blank"
                        />
                      )}

                      {store?.twitter && (
                        <ExternalLinkIcon
                          href={`https://twitter.com/${store?.twitter}`}
                          iconClassNames={"pi-twitter"}
                          color="text-black-alpha-70 hover:text-green-600"
                          width="text-base"
                          target="_blank"
                        />
                      )}

                      {store?.youtube && (
                        <ExternalLinkIcon
                          href={`https://youtube.com/${store?.youtube}`}
                          iconClassNames={"pi-youtube"}
                          color="text-black-alpha-70 hover:text-green-600 transition-all animation-duration-400"
                          width="text-base"
                          target="_blank"
                        />
                      )}
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default MapLeaflet;
