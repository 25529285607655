import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmDocument from "../../components/ConfirmDocument/ConfirmDocument";
import Navbar from "../../components/Navbar/Navbar";
import PayPalCheckoutCart from "../../components/PayPalCheckoutCart/PayPalCheckoutCart";
import { useToastContext } from "../../contexts/ToastContext/ToastContext";
import localeText from "../../locale";
import GetInfoService from "../../service/GetInfoService";
import GetUserInfoService from "../../service/GetUserInfoService";
import OrderService from "../../service/OrderService";
import { removelocalStorageToken } from "../../utilities/sessionStorage/token";
import { removelocalStorageUsername } from "../../utilities/sessionStorage/username";
import CartItems from "./components/CartItems/CartItems";
import Checkout from "./components/Checkout/Checkout";
import { Toast } from "primereact/toast";
import ThankYouPage from "./components/ThankYouPage/ThankYouPage";
import ErrorCheckout from "./components/ErrorCheckout/ErrorCheckout";
import paypalImg from "../../pages/images/paypal.png";
import masterCardImg from "../../pages/images/masterCard.png";
import americanExpress from "../../pages/images/expressoAmericano.png";
import visa from "../../pages/images/visa.png";
import PagamentoSeguro from "../../pages/images/pagamentoSeguro.png";

const ShoppingCart = () => {
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [currencyProduct, setCurrencyProduct] = useState("");
  const [currencyUser, setCurrencyUser] = useState("");
  const [page, setPage] = useState(1);
  const [amountTransfer, setAmountTransfer] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [walletBalanceForex, setWalletBalanceForex] = useState(null);
  const [walletExtracoinBalance, setWalletExtracoinBalance] = useState(null);
  const [walletExtracoinBalanceForex, setWalletExtracoinBalanceForex] = useState(null);
  const [isLoadingWalletExtracoinBalanceForex, setIsLoadingWalletExtracoinBalanceForex] =
    useState(true);
  const [cashback, setCashback] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [currencyCodeUser, setCurrencyCodeUser] = useState("USD");
  const [price, setPrice] = useState(0);
  const [priceForex, setPriceForex] = useState(0);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [addressInfo, setAddressInfo] = useState(null);
  const [amountWallet, setAmountWallet] = useState(null);
  const [userPercentExtracoin, setUserPercentExtracoin] = useState(null);
  const [isFreeExtracoin, setIsFreeExtracoin] = useState(false);
  const [isLoadingIsFreeExtracoin, setIsLoadingIsFreeExtracoin] = useState(true);
  const [sendMethod, setSendMethod] = useState(null);
  const [amountToPayWithExtracredit, setAmountToPayWithExtracredit] = useState(null);
  const [amountBankTransfer, setAmountBankTransfer] = useState(null);
  const [bankTransferReceipt, setBankTransferReceipt] = useState(null);
  const [currencyExchangeValue, setCurrencyExchangeValue] = useState(1);
  const { showErrorToast } = useToastContext();
  const [idTransaction, setIdTransaction] = useState(null);
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem("__extraconomy_order")));
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [orderIsEmpty, setOrderIsEmpty] = useState(
    !!!JSON.parse(localStorage.getItem("__extraconomy_order"))?.itens[0]
  );
  const [amountShipmentForexProduct, setAmountShipmentForexProduct] = useState(0);

  const userInfoService = new GetUserInfoService();
  const getInfoService = new GetInfoService();
  const getUserInfoService = new GetUserInfoService();

  const history = useHistory();

  const toast = useRef(null);

  // Check if user is eligible to free Extracoin
  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      if (localStorage.getItem("__extraconomy_token")) {
        const resAmountOrders = await getUserInfoService.getUserOrderCount(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        );
        if (cancel) return;

        const resPlan = await getUserInfoService.getUserTypeInfo(
          localStorage.getItem("__extraconomy_username")
        );

        if (resAmountOrders.messageCode !== 200001) {
          showErrorToast({ code: resAmountOrders.messageCode });
          setLoading(false);
          setIsLoadingIsFreeExtracoin(false);
          return false;
        }

        if (resPlan.messageCode !== 200001) {
          showErrorToast({ code: resPlan.messageCode });
          setLoading(false);
          setIsLoadingIsFreeExtracoin(false);
          return false;
        }

        if (
          (resPlan.result === "Customer" || resPlan.result === "Promoter") &&
          resAmountOrders.result < 3
        ) {
          setIsFreeExtracoin(true);
          setIsLoadingIsFreeExtracoin(false);
        }
      }
    }

    fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderService = new OrderService();

  const populateOrder = () => {
    if (
      order &&
      userPercentExtracoin &&
      !isLoadingWalletExtracoinBalanceForex &&
      isLoadingIsFreeExtracoin
    ) {
      if (isFreeExtracoin) {
        if (order?.itens?.length > 0) {
          setOrderItems(order?.itens);
          setOrderIsEmpty(false);
        }
        setOrder(order);
        setIsLoadingOrder(false);
      } else {
        if (order?.itens?.length > 0) {
          let totalExtracoinAvaible;

          if (walletExtracoinBalanceForex) {
            totalExtracoinAvaible = (walletExtracoinBalanceForex * userPercentExtracoin) / 100;
          } else {
            totalExtracoinAvaible = (walletExtracoinBalance * userPercentExtracoin) / 100;
          }

          order.itens.forEach(async (item, index) => {
            const totalToPayWithExtracoin = item.totalItem * (item.product.percentExtraCoin / 100);
            const totalToPay = item.totalItem;
            setCashback((prev) => prev + item.cashback);

            const isPossibleUseExtracoin = totalExtracoinAvaible / totalToPay;

            if (isPossibleUseExtracoin >= item.product.percentExtraCoin / 100) {
              totalExtracoinAvaible = totalExtracoinAvaible - totalToPayWithExtracoin;
              orderService
                .postEditItem(
                  localStorage.getItem("__extraconomy_token"),
                  localStorage.getItem("__extraconomy_username"),
                  item.id,
                  item.product.percentExtraCoin,
                  item.quantity
                )
                .then((res) => {
                  setOrder(res.result);
                  setOrderItems(res.result.itens);
                })
                .finally(() => {
                  if (index === order.itens.length - 1) setIsLoadingOrder(false);
                });
            } else if (isPossibleUseExtracoin > 0 && isPossibleUseExtracoin < 1) {
              totalExtracoinAvaible = 0;
              const percent = Math.floor(isPossibleUseExtracoin * 100);
              orderService
                .postEditItem(
                  localStorage.getItem("__extraconomy_token"),
                  localStorage.getItem("__extraconomy_username"),
                  item.id,
                  percent,
                  item.quantity
                )
                .then((res) => {
                  setOrder(res.result);
                  setOrderItems(res.result.itens);
                })
                .finally(() => {
                  if (index === order.itens.length - 1) setIsLoadingOrder(false);
                });
            } else {
              setOrderItems(order.itens);
              if (index === order.itens.length - 1) setIsLoadingOrder(false);
            }
          });
        }
        setOrder(order);
        setIsLoadingOrder(false);
      }
    }
  };

  useEffect(() => {
    populateOrder(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingWalletExtracoinBalanceForex, userPercentExtracoin, isLoadingIsFreeExtracoin]);

  // Set Currency and Wallet-related data
  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      try {
        if (order) {
          if (order?.itens[0]?.product?.currency) {
            setCurrencyProduct(order.itens[0].product.currency);
            setCurrencyCode(order.itens[0].product.currency);
          } else {
            setCurrencyProduct("USD");
            setCurrencyCode("USD");
          }

          const res = await userInfoService.getWalletInfo(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_username")
          );
          if (cancel) return;

          let resForex = 1;

          if (res.messageCode !== 200001) {
            showErrorToast({ code: res.messageCode });
          } else {
            setCurrencyUser(res.result.currency);
            setCurrencyCodeUser(res.result.currency);
            setWalletBalance(res.result.balance);

            if (order?.itens[0]?.product.currency !== res.result.currency) {
              resForex = await getInfoService.getForexRate(
                res.result.currency,
                order.itens[0].product.currency
              );

              if (resForex.messageCode !== 200001) {
                showErrorToast({ code: resForex.messageCode });
              } else {
                setCurrencyExchangeValue(resForex.result);
                setWalletBalanceForex(res.result.balance * resForex.result);
              }
            }
          }

          const resExtracoin = await getUserInfoService.getExtracoinInfo(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_username")
          );

          if (resExtracoin.messageCode !== 200001) {
            showErrorToast({ code: resExtracoin.messageCode });
          } else {
            setWalletExtracoinBalance(resExtracoin.result.balance);

            if (order.itens[0].product.currency !== resExtracoin.result.currency) {
              setWalletExtracoinBalanceForex(resExtracoin.result.balance * resForex.result);
            }
          }

          setIsLoadingWalletExtracoinBalanceForex(false);
        } else {
          setIsLoadingOrder(false);
          setOrderIsEmpty(true);
          setIsLoadingWalletExtracoinBalanceForex(false);
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set User info
  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      try {
        const res = await getUserInfoService.getExtracoinPercentage(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        );
        if (cancel) return;

        const resUser = await getUserInfoService.getUserInfo(
          localStorage.getItem("__extraconomy_token"),
          localStorage.getItem("__extraconomy_username")
        );

        if (res.messageCode !== 200001) {
          showErrorToast({ code: res.messageCode });
          return false;
        }

        if (resUser.messageCode !== 200001) {
          showErrorToast({ code: resUser.messageCode });
          return false;
        }

        setUserPercentExtracoin(res.result.percent);

        // Set user address info
        setUserInfo(resUser.result.user);
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          removelocalStorageToken();
          removelocalStorageUsername();
          history.push("/login");
        }
        console.log(e);
      }
    }

    if (
      localStorage.getItem("__extraconomy_token") &&
      localStorage.getItem("__extraconomy_username")
    )
      fetchData();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <Toast ref={toast} />

      <ConfirmDocument />

      <Dialog
        header={localeText("paymentCheckout")}
        visible={displayPaymentModal}
        onHide={() => {
          if (!loading) setDisplayPaymentModal(false);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {loading ? (
          <div className="text-center">
            <i className="text-6xl my-8 pi pi-spin pi-spinner text-400"></i>
          </div>
        ) : (
          <div className="lg:w-9 w-full mx-auto">
            <PayPalCheckoutCart
              userCurrency={currencyCodeUser}
              price={priceForex}
              loading={loading}
              setLoading={setLoading}
              addressInfo={addressInfo}
              amountWallet={amountWallet}
              priceOriginal={price}
              toast={toast}
              amountToPayWithExtracredit={amountToPayWithExtracredit}
              amountBankTransfer={amountBankTransfer}
              setDisplayPaymentModal={setDisplayPaymentModal}
              setPage={setPage}
              setIdTransaction={setIdTransaction}
            />
          </div>
        )}
      </Dialog>

      <div
        className={`card m-4 border-1 border-300 ${
          page === 3 && "sm:max-w-30rem w-100 sm:mx-auto mx-2"
        }`}
      >
        {page === 1 && (
          <CartItems
            walletBalance={walletBalance}
            order={order}
            cashback={cashback}
            setOrder={setOrder}
            setCashback={setCashback}
            isLoadingOrder={isLoadingOrder}
            currencyProduct={currencyProduct}
            currencyCodeUser={currencyCodeUser}
            currencyUser={currencyUser}
            setPage={setPage}
            setPrice={setPrice}
            orderItems={orderItems}
            setOrderItems={setOrderItems}
            walletExtracoinBalance={walletExtracoinBalance}
            userPercentExtracoin={userPercentExtracoin}
            walletExtracoinBalanceForex={walletExtracoinBalanceForex}
            setOrderIsEmpty={setOrderIsEmpty}
            orderIsEmpty={orderIsEmpty}
            isLoadingWalletExtracoinBalanceForex={isLoadingWalletExtracoinBalanceForex}
          />
        )}
        {page === 2 && (
          <Checkout
            order={order}
            orderItems={orderItems}
            setOrder={setOrder}
            amountShipmentForexProduct={amountShipmentForexProduct}
            setAmountShipmentForexProduct={setAmountShipmentForexProduct}
            currencyProduct={currencyProduct}
            currencyCodeUser={currencyCodeUser}
            currencyUser={currencyUser}
            setPage={setPage}
            setAmountTransfer={setAmountTransfer}
            setLoading={setLoading}
            setPrice={setPrice}
            amountWallet={amountWallet}
            currencyExchangeValue={currencyExchangeValue}
            amountToPayWithExtracredit={amountToPayWithExtracredit}
            setAmountToPayWithExtracredit={setAmountToPayWithExtracredit}
            amountBankTransfer={amountBankTransfer}
            setAmountBankTransfer={setAmountBankTransfer}
            bankTransferReceipt={bankTransferReceipt}
            setBankTransferReceipt={setBankTransferReceipt}
            walletBalance={walletBalance}
            walletBalanceForex={walletBalanceForex}
            price={price}
            currencyCode={currencyCode}
            setAmountWallet={setAmountWallet}
            walletExtracoinBalance={walletExtracoinBalance}
            userInfo={userInfo}
            userPercentExtracoin={userPercentExtracoin}
            setAddressInfo={setAddressInfo}
            setPriceForex={setPriceForex}
            setDisplayPaymentModal={setDisplayPaymentModal}
            sendMethod={sendMethod}
            setSendMethod={setSendMethod}
            walletExtracoinBalanceForex={walletExtracoinBalanceForex}
          />
        )}
        {page === 3 && (
          <ThankYouPage
            order={order}
            sendMethod={sendMethod}
            currencyCodeUser={currencyCodeUser}
            amountTransfer={amountTransfer}
          />
        )}
        {page === 4 && <ErrorCheckout order={order} idTransaction={idTransaction} />}
      </div>
    </div>
  );
};

export default ShoppingCart;
