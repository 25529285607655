import React from "react";
import "./style.css";
import { Card } from "primereact/card";
import SectionTwoAboutCard from "../SectionTwoAboutCard/SectionTwoAboutCard";
import AboutSectionImg from "../../../images/landing/about-section.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "../../landing.css";
import localeText from "../../../../locale";

export default function LandingAboutSectionOne() {
  return (
    <section className="gradient-background full-width">
      <div className="grid py-2 md:py-8">
        <div className="grid col-12 lg:col-8 m-0">
          <div
            className="text-5xl font-bold line-height-2 col-12 md:col-6 title"
            style={{ color: "#17633D" }}
          >
            {localeText("advantages")}
          </div>
          <div
            className=" col-12 md:col-6 flex md:justify-content-end justify-content-start"
            style={{ color: "#17633D" }}
          >
            <div className="text-md font-regular" style={{ maxWidth: "250px" }}>
              {localeText("createAnAccountForFree")} <strong>Extraconomy</strong>{" "}
              {localeText("createAnAccountForFree2")}.
            </div>
          </div>
          <div className="col-12 p-0 mt-7">
            <SectionTwoAboutCard />
          </div>
        </div>
        <div className="col flex flex-column gap-4 ">
          <Card
            className="h-full shadow-none bg-gray-200 border-round-lg"
            style={{
              backgroundImage: `url(${AboutSectionImg})`,
              backgroundSize: "cover",
              minHeight: "239px",
            }}
          ></Card>
          <div className="flex justify-content-center">
            <a
              text
              className="flex align-items-center gap-3 font-semibold p-button bg-white border-none"
              style={{ color: "#344563" }}
              href="https://wa.me/message/AALEU6LSOPNWM1"
            >
              <i className="pi pi-whatsapp text-2xl font-bold" style={{ color: "#46A748" }}></i>{" "}
              {localeText("contactUs")}
            </a>
          </div>
        </div>
        <div className="col-12 flex items-center justify-content-between">
          <div
            className="pt-6 flex flex-column gap-4"
            style={{ maxWidth: "487px", color: "#17633D" }}
          >
            <div className="text-md font-semibold line-height-2">
              {localeText("manyAdvantages")}
            </div>
            <div className="text-md font-normal">
              {localeText("monetaryAlternatives")}
              <Link
                to="/benefits"
                className="font-semibold"
                style={{ textDecoration: "underline", color: "#17633D" }}
              >
                {localeText("monetaryAlternatives2")}
              </Link>
            </div>
          </div>
          <div
            style={{ color: "#46A748" }}
            className="md:flex items-center align-items-center gap-2 justify-content-right hidden"
          >
            {localeText("scrollDown")} <i className="pi pi-arrow-down"></i>
          </div>
        </div>
      </div>
    </section>
  );
}
