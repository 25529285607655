const ExternalLinkIcon = ({
  href,
  iconClassNames,
  color = "text-white",
  width = "text-2xl",
  target = "_self",
}) => {
  return (
    <a href={href} target={target} rel="noreferrer">
      <i className={`pi ${iconClassNames} w-full ${width} ${color}`}></i>
    </a>
  );
};

export default ExternalLinkIcon;
