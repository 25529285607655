import { Button } from "primereact/button";
import { useState } from "react";
import "./style.css";
import localeText from "../../../../locale";

const LandingDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-64" style={{ height: "100%" }}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="border-none border-noround outline-none p-button-no-outline "

        style={{ backgroundColor: "#208C56", height: "100%", padding: "18px" }}
      >
        <i style={{fontSize: "24px"}} className={isOpen ? "pi pi-times" : "pi pi-bars"}></i>
      </Button>

      {isOpen && (
        <div
          style={{ backgroundColor: "#208C56", width: "100vw", borderTop: "1px solid #28AB69" }}
          className="absolute gap-4 flex flex-column align-items-center p-4"
        >
          <Button
            className="font-semibold text-white hover:underline p-0 focus:shadow-none "
            link
            onClick={() => props.scrollToSection("about")}
          >
            {localeText("benefits")}
          </Button>
          <hr className="p-0 m-0 w-full" style={{ borderTop: "solid 1px #28AB69" }} />
          <Button
            className="font-semibold text-white hover:underline p-0 focus:shadow-none "
            link
            onClick={() => props.scrollToSection("resources")}
          >
            {localeText("resources")}
          </Button>
          <hr className="p-0 m-0 w-full" style={{ borderTop: "solid 1px #28AB69" }} />
          <Button
            className="font-semibold text-white hover:underline p-0 focus:shadow-none "
            link
            onClick={() => props.scrollToSection("plans")}
          >
            {localeText("plans")}
          </Button>
          <hr className="p-0 m-0 w-full" style={{ borderTop: "solid 1px #28AB69" }} />
          <a
            href="https://extraconomy.blog/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-white hover:underline"
          >
            {localeText("blog")}
          </a>
        </div>
      )}
    </div>
  );
};

export default LandingDropdown;
