import { Link } from "react-router-dom/cjs/react-router-dom.min";
import localeText from "../../../../locale";

const FooterColumn = ({ heading, links, id }) => (
  <>
    <div className="text-md font-bold mb-3">{heading}</div>
    <div className="flex flex-column gap-3">
      {links.map((link) => (
        <Link
          to={link.url}
          key={link.id.toString()}
          className="text-md font-medium"
          style={{ color: "#D9E3FF" }}
        >
          {link.text}
        </Link>
      ))}
    </div>
  </>
);
const LandingFooterLinks = () => {
  const footerColumns = [
    {
      heading: "Extraconomy",
      links: [
        { text: localeText("discoverExtraconomy"), url: "/comingsoon", id: 10 },
        { text: localeText("workWithUs"), url: "/comingsoon", id: 11 },
        { text: localeText("rewardsProgram"), url: "/comingsoon", id: 12 },
        { text: "Extracoin", url: "/comingsoon", id: 13 },
      ],
      id: 1,
    },
    {
      heading: localeText("makeMoneyWithUs"),
      links: [
        { text: localeText("sellWithUs"), url: "/comingsoon", id: 20 },
        { text: localeText("becomeAffiliate"), url: "/comingsoon", id: 21 },
      ],
      id: 2,
    },
    {
      heading: localeText("ourPolicies"),
      links: [
        { text: localeText("shippingPolicy"), url: "/comingsoon", id: 30 },
        { text: localeText("privacyPolicy"), url: "/comingsoon", id: 31 },
        { text: localeText("returnPolicy"), url: "/comingsoon", id: 32 },
        { text: localeText("returnsAndRefunds"), url: "/comingsoon", id: 33 },
      ],
      id: 3,
    },
  ];
  return (
    <>
      {footerColumns.map((column) => (
        <div className="flex flex-column gap-3 col-12 md:col">
          <FooterColumn
            key={column.id.toString()}
            id={column.id}
            heading={column.heading}
            links={column.links}
          />
        </div>
      ))}
    </>
  );
};
export default LandingFooterLinks;
