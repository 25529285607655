import localeText from "../../../../locale";

const LandingCopyright = () => {
  const images = [
    { src: "paypal.png", id: 1 },
    { src: "visa.png", id: 2 },
    { src: "masterCard.png", id: 3 },
    { src: "expressoAmericano.png", id: 4 },
  ];
  return (
    <footer>
      <section className="md:py-5" style={{ backgroundColor: "#ffffff", color: "#312F80" }}>
        <div className="grid gap-3 m-0">
          <div className="col-12 md:col flex flex-column gap-4 align-items-center md:align-items-start">
            <div className="font-bold">{localeText("paymentMethods")}</div>
            <div className="flex flex-wrap gap-4">
              {images.map((image) => (
                <div className="flex align-items-center">
                  <img
                    key={image.id}
                    src={require(`../../../images/${image.src}`)}
                    alt={image.src}
                    style={{ width: "100%", maxWidth: "70px", maxHeight: "40px" }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 md:col flex flex-column gap-4 align-items-center md:align-items-start">
            <div className="font-bold">{localeText("shippingMethods")}</div>
            <div>
              <img
                src={require("../../../images/logo-iospedisco.png")}
                alt="visa"
                style={{ maxWidth: "150px" }}
              />
            </div>
          </div>
          <div className="col-12 md:col flex flex-column gap-4 align-items-center md:align-items-start">
            <div className="font-bold">{localeText("partners")}</div>
            <div className="flex flex-wrap gap-4 justify-content-center md:justify-content-start">
              {["cryptosmart.jpg", "fondoSanMarco.jpg", "pikoSolution.jpg", "grupoG8.jpg"].map(
                (image) => (
                  <div className="flex align-items-center">
                    <img
                      key={image}
                      src={require(`../../../images/parcerias/${image}`)}
                      alt={image}
                      style={{ maxHeight: "60px" }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>

    </footer>
  );
};

export default LandingCopyright;
