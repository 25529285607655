const landingDictES = {
  home: "Inicio",
  benefits: "Beneficios",
  benefitsExplanation: "Nuestra plataforma es una herramienta moderna, de fácil acceso y rentable.",
  firstBenefit: "Mayor integración comercial",
  secondBenefit: "Economía más ligera y potente",
  thirdBenefit: "Oportunidad de ganancias instantáneas y consistentes",
  fourthBenefit: "Generar crédito",
  features: "Características",
  howItWorks: "Cómo funciona",
  meetOurPlans: "Conozca nuestros planes",
  join: "Nuestros planes",
  plans: "Planes",
  login: "Entrar",
  whatIsIt: "El marketplace que siempre te premia",
  whatIsItExplanation:
    "Extraconomy es una plataforma online de economía cooperativa con un sistema de recompensas y geolocalización que permite a particulares y empresas incrementar su poder económico y su red de clientes de forma automática y progresiva, a través de las distintas actividades que realizan en el mercado.",
  why: "Por qué?",
  greaterBusiness: "Mayor integración comercial",
  lighterPowerful: "Economia más ligera y potente",
  instantProfit: "Oportunidad de beneficio instantáneo y constante",
  genCredit: "Genere crédito",
  whyShould: "Por qué debo unirme a la Extraconomy?",
  seeBelow: "Vea a continuación los recursos de nuestra red social y económica:",
  extracredit: "Extracredit",
  extracoinExplanation:
    "Regístrate y gana Extracredit (ver condiciones). Usa el crédito para comprar y/o compartir con tus amigos.",
  indicate: "Gane Créditos Extracredit",
  youMayGain: "Refiera usuarios a su red y gane créditos Extracredit y dinero real.",
  economicNetwork: "Red Económica",
  networkOfUsers: "Cree una red de usuarios para comprar y vender productos y servicios.",
  transactionSafety: "Seguridad de transacciones",
  ourPlatform:
    "Nuestra plataforma es robusta y segura. Puedes comprar, vender y transferir dinero de forma segura.",
  findEverything: "Encuentra todo lo que necesitas",
  weHavePowerfulTool:
    "Disponemos de una potente herramienta para encontrar el producto que desea adquirir en tan solo unos clics.",
  buyerReviews: "Reseñas de compradores",
  checkTheReviews:
    "Consulta las opiniones de los usuarios que han comprado el producto que buscas.",
  geolocation: "Geolocalización",
  effectiveTools: "Herramientas efectivas para hacer crecer automáticamente tu red económica.",
  exceptionalTrading: "Negocios Excepcionales",
  saferMoreFrequent: "Intercambios más seguros, frecuentes y ventajosos.",
  economicInfluence: "Influencia Económica",
  sellFaster: "Haga ventas más rápidas, compre más fácilmente, siempre obteniendo ganancias.",
  howItWorksExplanation:
    "Recibes recompensas por cada actividad que se realiza en la plataforma, en forma de dinero y créditos Extracredit que pueden ser utilizados dentro del sistema como complemento económico en intercambios comerciales.",
  contactWhatTele:
    "Para obtener información sobre el proyecto y sobre la apertura del mercado para Partners y Managers en un País, escriba a los seguientes contactos de Whatsapp y Telegram.",
  joinExtraconomy: "Únete a la Extraconomy.",
  belowArePlans: "A continuación se muestran los tipos de planes disponibles en nuestro servicio.",
  learnMore: "Más Información",
  doubts: "¿Dudas? ",
  contactToUs: "Contáctenos.",
  contact: "Contacto",
  scanQRCode: "Escanee el Código QR a continuación para contactarnos vía WhatsApp o Telegram:",
  highlights: "Destacados",
  by: " por ",
  video: "Video",
  introVideo: "Video de introducción sobre nuestra plataforma:",
  backToHomePage: "Volver a la Página de Inicio",
  qrCodeExplanation:
    "Para obtener información sobre el proyecto y sobre la apertura del mercado para Partners y Managers en un País, escriba a los seguientes contactos de Whatsapp y Telegram:",
  socialMedia: "Redes Sociales",
  rightsReserved: "Reservados todos los derechos",
  checkApp: "Consulta nuestra aplicación",
  customerDescription: "Solo para compras",
  partnerDescription: "Para ventas y compras",
  managerDescription: "Comercio electrónico + Compras",
  benefitsExtracoinSection: "Beneficios al comprar Extracoin",
  extracoinBenefitTitle1: "2.000 Extracoins: ¡Conviértase en Partner de Extraconomy!",
  extracoinBenefitDescription1:
    "Los poseedores de 2000 Extracoins reciben un vale gratuito para convertirse en Partners en Extraconomy Marketplace. Únase a la revolución y participe activamente en la configuración del futuro de la economía digital.",
  extracoinBenefitTitle2: "5.000 Extracoins: ¡Desbloquee el estado de Seller en Extraconomy!",
  extracoinBenefitDescription2:
    "Los poseedores de 5.000 Extracoins son recompensados con un cupón gratuito para convertirse en Sellers en la plataforma Extraconomy. Muestre sus productos y servicios a una audiencia global.",
  extracoinBenefitTitle3: "15.000 Extracoins: ¡Alcanza el estatus de Manager en Extraconomy!",
  extracoinBenefitDescription3:
    "Los poseedores de 15.000 Extracoins reciben un vale exclusivo para convertirse en Managers de Extraconomy. Sumérgete en el universo de la gestión y contribuye al crecimiento de todo el ecosistema.",
  voucherExtracoinText1: "¿Ya tienes un ",
  clickHere: "Haga clic aquí",
  andRegister: " y regístrese",
  voucherEmptyError: "El campo del cupón no puede estar vacío.",
  extracoinVoucherError: "El cupón ingresado no es válido.",
  startsWith: "Comienza con",
  contains: "Contiene",
  notContains: "No contiene",
  endsWith: "Termina con",
  equals: "igual",
  notEquals: "no es igual",
  noFilter: "Sin filtro",
  joinTheLaunchpad: "¡Únase al Launchpad de nuestro token Extracoin en Cryptosmart!",
  popupDesc:
    "¡Al comprar tokens EXC a un precio especial, recibirás a cambio un voucher gratis para abrir una tienda en línea en el mercado Extraconomy, activando tus ingresos diarios!",
  exclusiveBenefits: "Beneficios Exclusivos",
  popupPartnerTitle: "¡Convertirse en un Partner!",
  popupPartnerDesc:
    "*Al comprar un mínimo de 2000 Extracoin, recibirás un voucher gratuito para abrir un punto de venta en el mercado Extraconomy.",
  popupSellerTitle: "¡Desbloquea el perfil de Seller!",
  popupSellerDesc:
    "*Al comprar al menos 5.000 Extracoin recibirás un voucher gratis para abrir un comercio electrónico en la plataforma Extraconomy.",
  popupManagerTitle: "¡Alcanza el estatus de Manager!",
  popupManagerDesc:
    "*Al comprar un mínimo de 15.000 Extracoin recibirás un voucher exclusivo para abrir una tienda online en Extraconomy con todos los beneficios incluidos.",
  promotionValidUntilEndLaunchpad: "Promoción válida hasta el final de Launchpad",
  downloadAppAndEarn: "Descarga la aplicación ahora y gana 20 euros en créditos de inmediato",
  frequentlyAskedQuestion: "Preguntas frecuentes",
  faqTitle1: "¿La plataforma Extraconomy es segura?",
  faqAnswer1:
    "Sí, contamos con un equipo técnico especializado en seguridad de la información. Todos los contenidos, datos y operaciones financieras de la plataforma están protegidos contra múltiples niveles de seguridad, de modo que podamos mitigar al máximo cualquier tipo de ataque informático comprobable.",
  faqTitle2: "¿Cómo puedo participar en la plataforma?",
  faqAnswer2:
    "Si descubriste Extraconomy a través de alguien, ellos saben cómo incorporarte a la plataforma. En caso contrario, descarga nuestra App y regístrate introduciendo tus datos para acceder directamente a la Tienda. ¡Recibirás un crédito de bienvenida para utilizar en tus futuras compras!",
  faqTitle3: "¿Existen tarifas de registro para poder vender mis productos o servicios?",
  faqAnswer3:
    "Sí, para tener un espacio para tu escaparate o para tu e-commerce existen 3 planes de ventas con diferentes valores y beneficios dependiendo de las necesidades de cada usuario particular y empresarial. Puedes descubrir los Planes directamente aquí en esta web.",
  faqTitle4:
    "Cuando realizo actividades de promoción y ventas en la plataforma Extraconomy, ¿tengo una relación laboral?",
  faqAnswer4:
    "No, si te has suscrito a uno de los 3 planes de venta serás autónomo y serás totalmente responsable de tus gastos. Sólo si decides trabajar con nosotros como Promotor recibirás un contrato de Representante de Ventas.",
  faqTitle5: "¿Cómo puedo solicitar la eliminación de mi cuenta?",
  faqAnswer5:
    "Para cancelar tu cuenta Extraconomy simplemente inicia sesión en tu Área Personal y luego haz clic en Editar Perfil para solicitar la cancelación de la cuenta sin coste adicional. O puedes enviar un correo electrónico a support@extraconomyglobal.com utilizando el mismo correo electrónico que utilizas para acceder a Extraconomy, con el asunto “Cancelación de cuenta”.",
  faqTitle6: "Soy nuevo en la plataforma, ¿hay algún soporte?",
  faqAnswer6:
    "Sí, contamos con soporte humanizado y dedicado para ayudarte cuando lo necesites a través de los canales de Whatsapp y Telegram que puedes encontrar entre nuestros contactos aquí en el sitio web.",
  faqTitle7: "Proporcioné el correo electrónico incorrecto, ¿y ahora qué?",
  faqAnswer7:
    "Para cambiar su correo electrónico, deberá enviar un correo electrónico a support@extraconomyglobal.com con el asunto “Cambiar correo electrónico”, proporcionando todos los datos de registro utilizados en el registro y el correo electrónico correcto.",
  faqTitle8: "¿Cuáles son las opciones de pago?",
  faqAnswer8:
    "A través de PayPal, nuestra pasarela de pago internacional, podrás pagar tu pedido mediante tarjeta de crédito, tarjeta de débito o cuenta bancaria.",
  faqTitle9: "¿Cuánto cuesta el envío a mi región?",
  faqAnswer9:
    "Para este cálculo, la forma más sencilla es simular su pedido. Elige los artículos con Envío Pagado, agrégalos al carrito y completa la compra, introduce tus datos correctamente (muy importante) y al finalizar el pedido la web te mostrará el coste de envío correcto. Si solo está simulando, no haga clic en Pagar y no resultará en ninguna compra real de su parte. Luego edite los artículos y las cantidades para ver las diferencias, siéntase libre.",
  faqTitle10: "¿Cómo funcionan los créditos adquiridos en Extraconomy?",
  faqAnswer10:
    "Los créditos internos se adquieren a través de las diversas actividades realizadas en la plataforma (Registros, Compra-Venta y Compartir) en forma de cashbacks, premios y bonificaciones, los cuales pueden ser utilizados de forma complementaria en compras dentro de la plataforma o en actividades de marketing. en base a las condiciones de uso previstas para cada perfil de usuario.",
  faqTitle11:
    "¿Cómo puedo monitorear y administrar mis pagos, comisiones y bonos recibidos en Extraconomy?",
  faqAnswer11:
    "Al registrarte a través de la Web y la App, recibes una oficina virtual en tu Área Personal donde podrás controlar todos tus ingresos financieros directos y los de tu círculo económico.",
  faqTitle12: "¿Cómo recibo mis ingresos financieros?",
  faqAnswer12:
    "Los ingresos económicos directos e indirectos se acreditan en tu cuenta PayPal, que deberás introducir en tu Área Personal. Si no tiene una cuenta PayPal, puede crear una gratis en www.paypal.com.",
  SeeTheRegulationsHere: "Ver el reglamento aquí",
  joinTheLaunchpadButton: "Únete al Launchpad",
  tax: "Tasa",
  workWithUs: "Trabaja con nosotros",
  jobOpportunity: "Oportunidad de trabajo",
  workWithUsText1:
    "El sistema Extraconomy ofrece la posibilidad de participar en el desarrollo online y territorial a través de figuras clave (promotor web, embajador, agente comercial, microinfluencer, afiliados del mercado) que se beneficiarán automáticamente de un sistema de comisiones exclusivo, escalable y gratificante.",
  freePlan: "Gratuito",
  salesRepresentativeContract: "Contrato de representante de ventas",
  earnCommissionsAndCredits: "Gane comisiones y créditos",
  developYourNetwork: "Desarrolle su red",
  financialCareerAwards: "Premios de carrera financiera",
  resources: "Recursos",
  blog: "Blog",
  toTheStore: "ACCEDER A LA TIENDA",
  revolutionizeWithExtraconomy: "Revuelva sus negocios con Extraconomy",
  revolutionizeWithExtraconomyDescription:
    "Transforme sus gastos diarios en ganancias y participe de una economia mas sostenible.",
  iWantToStart: "Quiero revolucionar la economia.",
  extrapayBalance: "Saldo ExtraPay",
  viewCashback: "Ver estrato del cashback",
  partnerPlan: "Piano Partner",
  empowerYourBusiness: "Potencie su negocio",
  empowerYourBusinessDescription:
    "Extraconomy es un modelo economico que une tecnologia, comunidad y cooperacion. Creamos un sistema innovador que amplifica el poder economico de las personas y sostiene las actividades locales.",
  comercialIntegration: "Mayor integracion comercial",
  comercialIntegrationDescription:
    "Nuestros instrumentos potencian la integracion entre tiendas y clientes, basandose en el modelo de geolocalizacion.",
  powerfulEconomy: "Una economia mas rapida y potente",
  powerfulEconomyDescription:
    "Reforzamos la economia local y ayudamos a los comercios locales a ganar mas visibilidad.",
  instantProfitDescription:
    "Con el circulo economico activo, comience a ganar dinero desde el momento de la registro y lo amplie con compras e indicaciones.",
  generateCredit: "Generar mas credito",
  generateCreditDescription: "Puede ganar dinero mientras hace las compras diarias.",
  advantages: "Tenemos muchas ventajas para usted",
  createAnAccountForFree: "Cree una cuenta gratuita y descubra todas las ventajas que",
  createAnAccountForFree2: "le ofrece",
  geolocalization: "Geolocalizacion",
  geolocalizationDescription: "Descubra servicios y empresas locales y reforzamos su region.",
  coperativeEconomy: "Economia cooperativa",
  coperativeEconomyDescription:
    "Participe activamente en la construccion de un futuro mas inclusivo.",
  cashback: "Cashback automatico",
  cashbackDescription: "Economice y acumule premios en todas sus compras.",
  pension: "Previdencia Integrativa",
  pensionDescription: "Asegure su futuro con el dinero automatico para salud y previdencia.",
  contactUs: "Contactenos en WhatsApp",
  manyAdvantages: "Existen muchas ventajas!",
  monetaryAlternatives: "Use tambien nuestra alternativa monetaria, economizara mas pagando con ",
  monetaryAlternatives2: "utility-token Extracoin!",
  scrollDown: "Continue a explorar",
  exclusiveResources: "RECURSOS ESPECIAIS",
  whyExtraconomy: "Porque usted debe unirse a Extraconomy?",
  whyExtraconomyDescription:
    "Con los recursos de nuestra red social y economica, usted puede potenciar sus ganancias. Descubra todos los beneficios de Extraconomy y unirse a nosotros.",
  newAcquisition: "Nueva adquisicion",
  newSale: "Nueva venta",
  localSales: "Venta local con retiro.",
  descExtracredit:
    "Registrese y obtenga el Extracredit (vea las condiciones). Use el credito para comprar y/o compartir con sus amigos.",
  descEconomicNetwork:
    "Cree automaticamente una red de usuarios para comprar y vender productos y servicios.",
  descTransactionSafety:
    "Nuestra plataforma es robusta y segura. Usted puede comprar, vender y transferir dinero de manera segura.",
  descFindEverything:
    "Tenemos una herramienta potente para encontrar el producto que usted desea comprar en tan solo unos clics.",
  plansAdvantages: "VANTAGENS DE CADA PLANO",
  chooseYourPlanTitle: "Elija el plano que mejor se adapte a su perfil.",
  chooseYourPlanDescription:
    "Conozca nuestros planes, elija el que mejor se adapte a su perfil y unirse a Extraconomy.",
  noMonthlyFees: "SIN TARIFAS MENSUALES",
  noMonthlyPaymentsDesc: "Usted no tendra que hacer pagos mensuales.",
  partner: "PARTNER",
  naturalPerson: "Persona Fisica",
  salesPlan: "Plano de Ventas",
  selectPlan: "SELECCIONAR",
  legalOrNaturalPerson: "Persona Fisica / Persona Juridica",
  featureShowcase: "Vitrina online",
  featureEconomicNetwork: "Red Economica",
  featureActiveComission: "Comision activa",
  featureEcommerce: "E-commerce personal",
  featureDropshiping: "Posee Dropshipping",
  featureOnlineStore: "Tienda online",
  featureGeolocation: "Geolocalizacion activa",
  featureEvrythingIncluded: "Todo incluso",
  mostPopular: "mas popular",
  doubtsAboutPlans: "Tiene dudas sobre cual plano es el mejor?",
  doubtsAboutPlansDesc:
    "Envie un mensaje a nuestro servicio de clientes y ayudaremos usted a elegir el plano mas adecuado para su perfil.",
  workWithUsSubtitle: "TRABAJE CON NOSOTROS",
  workWithUsTitle: "Sea un Promotor de Extraconomy y multiplique sus ganancias.",
  workWithUsDescription:
    "El sistema Extraconomy ofrece la posibilidad de participar en el desarrollo online y territorial a través de figuras clave (promotores web, embajadores, agentes comerciales, micro-influencers, afiliados de mercado), que se beneficiarán automáticamente de un sistema de comisiones exclusivo, escalable y gratificante.",
  doYouWantToWork: "Quiere ser un Promotor?",
  extracredits: "Reciba 500 R$ en credito extra.",
  moreBonus: "Bonus",
  moreBonusesDescription: "Premios para la carrera financiera.",
  recurrence: "Recurrencia",
  recurrenceDescription: "Desarrolle su red y aumente su recurrencia de ganancias.",
  comissions: "Comisiones",
  comissionsDescription: "Ganhe comisiones e credito.",
  clarify: "Clarifique sus dudas",
  frequentlyAskedQuestionDescription:
    "Recopilamos algunas preguntas y respuestas que pueden ayudarle en su eleccion.",
  doubtsDescription: "Envie un mensaje a nuestro equipo.",
  joinUs: "Unirse a nosotros",
  joinUsDescription: "Regístrese y transformemos juntos la economía.",
  talkWithUs: "Hable con nuestro equipo",
  talkWithUsDescription: "Hable con nuestro equipo comercial.",
  needHelp: "Necesita ayuda?",
  call: "Llame",
  callDescription: "Esté en contacto con nosotros",
  buyInWhatsapp: "Compre en WhatsApp",
  buyInWhatsappDescription: "Rápido y seguro",
  assistance: "Asistencia",
  assistanceDescription: "Respondemos a todas sus preguntas",
  followUs: "Siga nuestros canales oficiales",
  shippingMethods: "Modalidades de envio",
  partners: "Partners",
  discoverExtraconomy: "Descubra Extraconomy",
  rewardsProgram: "Programa de recompensa",
  makeMoneyWithUs: "Ganhe dinheiro",
  sellWithUs: "Venda conosco",
  becomeAffiliate: "Seja um afiliado",
  ourPolicies: "Nossas políticas",
  shippingPolicy: "Politica de envio",
  privacyPolicy: "Politica de privacidade",
  returnPolicy: "Politica de devolução",
  returnsAndRefunds: "Devoluções e substituições",
  partnerPlanDescription: "Para quem está commencendo a vender online.",
  sellerPlanDescription: "Para quem deseja aumentar suas vendas.",
  managerPlanDescription: "Para quem deseja ampliar o seu negócio.",
  planSeller: "VENDEDOR",
};

export default landingDictES;
