import { Toast } from "primereact/toast";
import { useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css"; // Tema PrimeReact
import "primereact/resources/primereact.min.css"; // Estilos do PrimeReact
import "primeicons/primeicons.css"; // Ícones do PrimeReact
import LandingHeader from "./components/LandingHeader/LandingHeader";
import LandingDropdown from "./components/LandingDropdown/LandingDropdown";
import LandingNav from "./components/LandingNav/LandingNav";
import "./landing.css";
import LandingAboutSectionOne from "./components/LandingAboutSectionOne/LandingAboutSectionOne";
import LandingAboutSectionTwo from "./components/LandingAboutSectionTwo/LandingAboutSectionTwo";
import LandingFooter from "./components/LandingFooter/LandingFooter";
import LandingExclusiveSection from "./components/LandingExclusiveSection/LandingExclusiveSection";
import LandingPlans from "./components/LandingPlans/LandingPlans";
import LandingWorkWithUs from "./components/LandingWorkWithUs/LandingWorkWithUs";
import LandingFaqComponent from "./components/LandingFaq/LandingFaqComponent";
import localeText from "../../locale";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SEO from "../../components/SEO/SEO";
import LandingCopyright from "./components/LandingCopyright/LandingCopyright";

function LandingPage() {
  const toast = useRef(null);
  const scrollToSection = (elementId) => {
    console.log(elementId);
    const element = document.getElementById(elementId);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <SEO
        title="Extraconomy"
        image="https://extraconomy.com/static/media/logo-color-horizontal.05fe6ab611037d6aaf49.png"
      />
      <Toast ref={toast} />

      {/* Navbar */}
      <div
        style={{ backgroundColor: "#208C56", margin: "0" }}
        className="flex align-items-center justify-content-between nav-responsive sticky top-0 z-3"
      >
        <div className="md:hidden flex h-full">
          <LandingDropdown scrollToSection={scrollToSection} />
        </div>
        <LandingNav scrollToSection={scrollToSection} />
        <Link
          className="font-bold gap-3 md:px-6 px-4 border-noround border-none p-button-no-outline p-button-cart-hover flex align-items-center text-white"
          style={{ backgroundColor: "#26235E", fontSize: "16px", height: "100%" }}
          to="/shop"
        >
          <i style={{ fontSize: "24px" }} className="pi pi-shopping-cart"></i>{" "}
          <span className="hidden md:inline font-system">{localeText("toTheStore")}</span>
        </Link>
      </div>

      <div className="bg-white content-grid landing">
        {/* Cabeçalho */}
        <LandingHeader scrollToSection={scrollToSection} />
        {/* Sobre / secção 1 / secção 2*/}
        <LandingAboutSectionOne />
        <LandingAboutSectionTwo />

        {/* Vantagens */}
        <LandingExclusiveSection />

        {/* Planos */}
        <LandingPlans />

        {/* Trabalhe conosco */}
        <LandingWorkWithUs />

        {/* FAQ */}
        <LandingFaqComponent />

        {/* Footer */}
        <LandingFooter />
        <LandingCopyright />
        <section
          className="md:py-5 full-width"
          style={{ backgroundColor: "#FFFFFF", color: "#7A869A", borderTop: "1px solid #E0E7FF" }}
        >
          <div className="grid gap-3 m-0">
            <div className="col-12 md:col text-center md:text-left">
              <div className="text-sm font-regular">EXTRACONOMY GLOBAL srl</div>
              <div className="text-sm font-regular">Via Nomentana, 1018 - 00137 Roma (IT)</div>
            </div>
            <div className="col-12 md:col text-center md:text-left">
              <div className="text-sm font-regular">P. Iva e C.F. 16948681008 | REA 1686351</div>
            </div>
            <div className="col-12 md:col text-center md:text-right">
              <div className="text-sm font-regular">Privacy Policy</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LandingPage;
