const adminBackofficeDictEN = {
  adverts: "Adverts",
  users: "Users",
  orders: "Orders",
  paidSubscriptions: "Paid Subscriptions",
  paymentMethod: "Payment method",
  initialSub: "Initial Registration",
  annualRenewal: "Annual Renewal",
  test: "test",
  adminDashboard: "Admin Dashboard",
  withdrawRequests: "Withdraw requests",
  requestDate: "Request date",
  processDate: "Process date",
  processing: "Processing",
  amountToDeposit: "Amount to deposit",
  amountRequested: "Amount requested",
  processRequest: "Process request",
  process: "Process",
  processedBy: "Processed by",
  withdrawProcessedSuccessfully: "Withdraw request processed successfully!",
  blockOrUnblockUser: "Block/Unblock user",
  block: "Block",
  unblock: "Unblock",
  user: "User",
  processThisRequest: "Process this request?",
  userBlockedSuccessfully: "User blocked successfully.",
  userUnblockedSuccessfully: "User unblocked successfully!",
  areYouSureBlockUser: "Are you sure you want to block this user?",
  areYouSureUnblockUser: "Are you sure you want to unblock this user?",
  blocked: "Blocked",
  yes: "Yes",
  no: "No",
  category: "Category",
  searchCategory: "Search Category",
  categoriesRegistered: "Registered Categories",
  image: "Image",
  descriptionPT: "Portuguese description",
  descriptionIT: "Italian description",
  descriptionES: "Spanish description",
  descriptionEN: "English description",
  registerNewCategory: "Register new Category",
  addNewCategory: "Add New Category",
  newCategory: "New",
  categoryInfo: "Category info",
  categoryFields: "Fill in the fields with the category information.",
  categoryImage: "Category Image",
  dragImg: "Drag your image here",
  registerCategory: "Register",
  descriptionBrand: "Brand description",
  brandsRegistered: "Trademarks",
  brands: "Brands",
  addNewBrand: "Add new brand",
  brandInfo: "Brand info",
  brandFields: "Fill in the field with brand information.",
  registerBrand: "Register",
  newBrand: "New",
  typesCategoriesRegistered: "Types Categories Registered",
  descriptionType: "Description",
  descriptionTypeEN: "English description",
  descriptionTypeES: "Spanish description",
  descriptionTypeIT: "Italian Description",
  descriptionTypePT: "Portuguese description",
  types: "Types",
  addNewType: "Add new types",
  typeInfo: "Type information",
  typeFields: "Fill in the fields with the type information.",
  newType: "New",
  registerType: "Register",
  subcategory: "Subcategories",
  newsubcategory: "New",
  subcategoriesRegistered: "Registered Subcategories",
  descriptionSubcategoryEN: "English Description",
  descriptionSubcategoryIT: "Italian Description",
  descriptionSubcategoryPT: "Portuguese Description",
  descriptionSubcategoryES: "Spanish Description",
  service: "service",
  addNewSubcategory: "Add New Subcategory",
  subcategoryInfo: "Subcategory information",
  subcategoryFields: "Fill in the fields with the subcategory information.",
  subcategoryImage: "Subcategory image",
  registerSubcategory: "Register",
  chooseSubcategory: "Choose at least one subcategory",
  address: "Address",
  userType: "User type",
  chooseUser: "choose a user",
  chooseAddress: "choose an address",
  UserAddressOnSendCloud: "Was the user's address registered in sendcloud?",
  searchUser: "Search user.",
  userInfo: "User information",
  adminBackofficeName: "Name",
  hasNoAttribute: "Has no",
  adminBackofficeUserType: "User type",
  adminBackofficeCountry: "Country",
  adminBackofficeAddress: "Address",
  adminBackofficeCity: "City",
  adminBackofficeState: "State",
  adminBackofficeDistrict: "District",
  adminBackofficePostalCode: "Postal code",
  adminBackofficeNumber: "Number",
  adminBackofficeComplement: "Complement",
  adminBackofficeDateCreation: "Date of creation",
  adminBackofficePhoneNumber: "Phone",
  adminBackofficeOriginRegister: "Origin of registration",
  searchByEmail: "search by email",
  searchByFirstname: "search by name",
  searchByPhonenumber: "Search by phone number",
  searchByReferralName: "Search by referral",
  searchByBrand: "search by brand",
  usersOfSystem: "System users",
  productsNotPublished: "Products not published",
  archivedProducts: "Archived products",
  productsPublished: "Published products",
  salesRevenue: "Sales revenue",
  feeRevenue: "Fee revenue",
  registeredStores: "Registered stores",
  completedOrders: "Completed orders",
  revenueFromUserRegistration: "Revenue from user registration",
  productsOfSystem: "System products",
  filters: "Filters",
  search: "Search",
  searchByNameSurnameUserAccountBank: "Search by name, surname, user, bank account",
  ordersPlacedWithBangood: "Orders placed with Bangood",
  actions: "Actions",
  processOrderSuccessfully: "Order processed successfully!",
  metaAdsConfig: "Setting up Meta Ads",
  integrationConfig: "Integration settings",
  editIntegrationConfig: "Edit configuration",
  accessToken: "Access token",
  pixelNumberId: "Pixel ID number",
  savePixelConfig: "Save configuration",
  integrations: "Integrations",
  pixelConfigSaved: "Configuration saved!",
  pixelConfigSavedDetail: "Your Meta ad settings have been successfully saved.",
  pixelConfigError: "Unexpected error",
  pixelConfigErrorDetail: "An error occurred while trying to save your settings.",
  fillInAllFields: "Fill in all the fields!",
  googleTagManagerConfiguration: "Configure Google Tag Manager",
  gtmCodeSaved: "GTM code saved",
  gtmCode: "GTM Code",
  gtmEdit: "Edit GTM Code",
  gtmRegister: "Register GTM Code",
  confirmed: "Payment Confirmed",
  paymentConfirmed: "You have successfully confirmed receipt of payment by bank transfer.",
  pendingOrders: "Pending orders",
  transferConfirm: "Confirm Transfer",
  buyer: "Buyer",
  totalAmount: "Total Paid",
  date: "Date",
  noPendingOrders: "No pending bank transfer orders.",
  youHavePendingOrders: "You have pending sales",
  youHavePendingUsers: "You have pending users",
  seeYourPendingOrders: "Check your pending bank transfer sales.",
  seeYourPendingUsersBankTransfer: "Check your pending bank transfer users.",
  pendings: "Pending sales",
  usersPendings: "Pending users",
  bankTransfers: "Bank transfers",
  receipt: "Receipt",
  searchForPlan: "Search by plan",
  authorize: "Authorize",
  confirmTransfer: "Confirm transfer?",
  pendingIssues: "Pending issues",
  quantityOfWithdrawsCompleted: "Quantity of completed withdrawals",
  totalAmountWithdrawsCompleted: "Total amount of completed withdrawals",
  totalOfUsersPublishedAds: "Total of users with published ads",
  totalofCommissionsPaid: "Total of commissions paid",
  youHaveUnprocessedOrders: "You have unprocessed Bangood orders",
  pendingBangoodOrders: "View your unprocessed Bangood orders.",
};

export default adminBackofficeDictEN;
