import React from "react";
import "./style.css";
import "../../landing.css";
import localeText from "../../../../locale";

const LandingExclusiveSection = () => {
  const resources = [
    {
      icon: "phone-icon.svg",
      title: "Extracredit",
      description: localeText("descExtracredit"),
    },
    {
      icon: "money-icon.svg",
      title: localeText("economicNetwork"),
      description: localeText("descEconomicNetwork"),
    },
    {
      icon: "graph-icon.svg",
      title: localeText("transactionSafety"),
      description: localeText("descTransactionSafety"),
    },
    {
      icon: "card-icon.svg",
      title: localeText("findEverything"),
      description: localeText("descFindEverything"),
    },
  ];
  return (
    <section className=" full-width" style={{ backgroundColor: "#FCFCFC" }} id="resources">
      <div className="grid m-0">
        <div className="col-12 md:col py-4 md:py-8 flex flex-column gap-5 pr-6 flex-order-1 md:flex-order-0">
          <div className="text-md font-bold" style={{ color: "#433AC8" }}>
            {localeText("exclusiveResources")}
          </div>
          <div className="text-5xl font-bold line-height-2 title" style={{ color: "#344563" }}>
            {localeText("whyExtraconomy")}
          </div>
          <div className="text-md my-4" style={{ color: "#505F79" }}>
            {localeText("whyExtraconomyDescription")}
          </div>
          <div className="flex flex-column gap-4">
            {resources.map((resource, index) => (
              <div key={index} className="pb-4" style={{ borderBottom: "1px solid #8CACFF" }}>
                <div className="grid align-items-top">
                  <div className="col-12 md:col-1 text-left md:text-center ">
                    <img src={require(`../../../images/landing/${resource.icon}`)} alt="icon" />
                  </div>

                  <div className="col">
                    <div style={{ color: "#505F79" }}>
                      <span className="font-bold">{resource.title}</span>:{" "}
                      <span>{resource.description}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col background-exclusive-section relative">
          <div
            className="card absolute m-0 border-round-lg border-none text-white"
            style={{
              left: "30px",
              top: "30px",
              backdropFilter: "blur(4px)",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
          >
            <div className="text-xs opacity-70">{localeText("newAcquisition")}</div>
            <div className="font-bold text-2xl title">€ 120,00</div>
            <div className="text-xs opacity-70">€ 16,00 cashback</div>
          </div>
          <div
            className="card absolute m-0 border-round-lg border-none text-white"
            style={{
              right: "30px",
              bottom: "30px",
              backdropFilter: "blur(4px)",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
          >
            <div className="text-xs opacity-70">{localeText("newSale")}</div>
            <div className="font-bold text-2xl title">€ 120,00</div>
            <div className="text-xs opacity-70">{localeText("localSales")}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingExclusiveSection;
