import axios from "axios";
import apiURI from "./Api";

export default class AdvancedPayPalService {
  async createOrder(payload) {
    /* const payload = {
      intent: "CAPTURE",
      purchase_units: [data],
    }; */
    console.log(payload);
    const response = await axios({
      method: "post",
      url: `${apiURI}/paypaladvanced/create-order`,
      data: payload,
    });
    return response.data;
  }
  async captureOrder(orderId) {
    const response = await axios({
      method: "post",
      url: `${apiURI}/paypaladvanced/capture-payment?orderId=${orderId}`,
    });
    return response.data;
  }
}
