const statementTableDictIT = {
  walletStatement: "Estratto conto portafoglio",
  extracoinStatement: "Estratto di Extracredit",
  extrabonusStatement: "Estratto Bonus Ads",
  bonusStatement: "Estratto di rendita giornaliera a vita",
  from: "Dal",
  toFrom: "Al",
  check: "Vedere",
  statement: "Estratto",
  description: "Descrizione",
  status: "Stato",
  dateTransaction: "Data della Transazione",
  emptyDates: "Si prega di scegliere un periodo di due date.",
  completed: "Completato",
  pending: "Pedente",
  pendingVoucher: "Buono in attesa",
  canceled: "Annullata",
};

export default statementTableDictIT;
