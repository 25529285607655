import React, { useRef } from "react";
import localeText from "../../../../locale";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./style.css";

const LandingPlansCards = (props) => {
  const scrollContainerRef = useRef(null);

  const scrollToCard = (direction) => {
    if (!scrollContainerRef.current) return;

    const container = scrollContainerRef.current;
    const cardWidth = container.querySelector(".card-wrapper").offsetWidth;
    const scrollAmount = direction === "left" ? -cardWidth : cardWidth;

    container.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        ref={scrollContainerRef}
        className="card-container pt-6 pb-3 md:pb-6  h-full"
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          className="card border-round-lg p-0 border-none py-3 card-wrapper flex flex-column "
          style={{
            boxShadow: "0px 0px 40px  #5F58521A",
          }}
        >
          <div style={{ borderBottom: "1px solid #7EE5C0" }}>
            <div className="p-5 flex flex-column gap-1">
              <div className="font-medium text-lg" style={{ color: "#091E42" }}>
                {localeText("profile")}
              </div>
              <div className="font-bold text-4xl" style={{ color: "#208C56" }}>
                {localeText("partner")}
              </div>
              <div className="mt-1">
                <Tag
                  className="font-bold"
                  value="PRO SILVER"
                  style={{ backgroundColor: "#505F79", fontSize: "10px", letterSpacing: "1px" }}
                />
                <div className="text-sm mt-2" style={{ color: "#7A869A" }}>
                  {localeText("partnerPlanDescription")}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 flex flex-column justify-content-between" style={{ flex: 1 }}>
            <div>
              {props.featuresCard1.map((feature, index) => (
                <div key={index} className="flex items-end gap-3 mb-3">
                  <div>
                    <i
                      className="pi pi-check-circle"
                      style={{ color: "#7EE5C0", fontSize: "1.5em" }}
                    ></i>
                  </div>

                  <span className="text-md" style={{ color: "#344563" }}>
                    {feature}
                  </span>
                </div>
              ))}
            </div>
            <Link
              to="/businesspartner"
              className="w-full flex justify-content-center p-3 text-white font-medium border-round-lg mt-4 p-button"
              style={{ backgroundColor: "#28AB69" }}
            >
              {localeText("selectPlan")}
            </Link>
          </div>
        </div>
        <div
          className="card border-round-lg p-0 relative border-none py-3 card-wrapper flex flex-column h-full"
          style={{
            boxShadow: "0px 0px 40px  #5F58521A",
            backgroundColor: "#26235E",
          }}
        >
          <Tag
            className="absolute top-0 right-0 m-3 font-medium"
            value={localeText("mostPopular")}
            style={{ backgroundColor: "white", color: "#28AB69" }}
          />
          <div style={{ borderBottom: "1px solid #7EE5C0" }}>
            <div className="p-5 flex flex-column gap-1">
              <div className="font-medium text-lg text-white">{localeText("profile")}</div>
              <div className="font-bold text-4xl" style={{ color: "#4ED372" }}>
                {localeText("planSeller")}
              </div>
              <div className="mt-1">
                <Tag
                  className="font-bold"
                  value="START SELLER"
                  style={{ backgroundColor: "#4ED372", fontSize: "10px", letterSpacing: "1px" }}
                />
                <div className="text-sm mt-2" style={{ color: "#FAFBFC" }}>
                  {localeText("sellerPlanDescription")}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 flex flex-column justify-content-between">
            <div>
              {props.featuresCard2.map((feature, index) => (
                <div key={index} className="flex items-end gap-3 mb-3">
                  <div>
                    <i
                      className="pi pi-check-circle"
                      style={{ color: "#28AB69", fontSize: "1.5em" }}
                    ></i>
                  </div>

                  <span className="text-md" style={{ color: "#FAFBFC" }}>
                    {feature}
                  </span>
                </div>
              ))}
            </div>

            <Link
              to="/seller"
              className="w-full flex justify-content-center p-3 text-white font-medium border-round-lg mt-4 p-button"
              style={{ backgroundColor: "#28AB69" }}
            >
              {localeText("selectPlan")}
            </Link>
          </div>
        </div>
        <div
          className="card border-round-lg p-0 border-none py-3 card-wrapper flex flex-column"
          style={{
            boxShadow: "0px 0px 40px  #5F58521A",
          }}
        >
          <div style={{ borderBottom: "1px solid #7EE5C0" }}>
            <div className="p-5 flex flex-column gap-1">
              <div className="font-medium text-lg" style={{ color: "#091E42" }}>
                {localeText("profile")}
              </div>
              <div className="font-bold text-4xl" style={{ color: "#208C56" }}>
                MANAGER
              </div>

              <div className="mt-1">
                <Tag
                  className="font-bold"
                  value="GEO GOLD"
                  style={{
                    backgroundColor: "#FFC839",
                    color: "#091E42",
                    fontSize: "10px",
                    letterSpacing: "1px",
                  }}
                />
                <div className="text-sm mt-2" style={{ color: "#7A869A" }}>
                  {localeText("managerPlanDescription")}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 flex flex-column justify-content-between" style={{ flex: 1 }}>
            <div>
              {props.featuresCard3.map((feature, index) => (
                <div key={index} className="flex items-end gap-3 mb-3">
                  <div>
                    <i
                      className="pi pi-check-circle"
                      style={{ color: "#7EE5C0", fontSize: "1.5em" }}
                    ></i>
                  </div>

                  <span className="text-md" style={{ color: "#344563" }}>
                    {feature}
                  </span>
                </div>
              ))}
            </div>

            <Link
              to="/areamanager"
              className="w-full flex justify-content-center p-3 text-white font-medium border-round-lg mt-4 p-button"
              style={{ backgroundColor: "#28AB69" }}
            >
              {localeText("selectPlan")}
            </Link>
          </div>
        </div>
      </div>
      {/* Navigation Buttons for Mobile and Link to details */}
      {/* Link for details is not aproved for now */}
      <div className="flex justify-content-between align-items-center">
        <Link
          className="font-bold border-noround border-none text-lg hidden"
          style={{ textDecoration: "underline", color: "#28AB69" }}
          to="/"
        >
          Confrontare i piani
        </Link>
        <div className="nav-buttons flex md:hidden gap-4">
          <button
            onClick={() => scrollToCard("left")}
            className="nav-button bg-white border-circle"
            style={{ width: "48px", height: "48px", border: "1px solid #ACE6B8" }}
            aria-label="Previous card"
          >
            <i className="pi pi-chevron-left" style={{ color: "#204C21" }}></i>
          </button>
          <button
            onClick={() => scrollToCard("right")}
            className="nav-button bg-white border-circle"
            style={{ width: "48px", height: "48px", border: "1px solid #ACE6B8" }}
            aria-label="Next card"
          >
            <i className="pi pi-chevron-right" style={{ color: "#204C21" }}></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPlansCards;
