import { Avatar } from "primereact/avatar";
import React from "react";
import logoWhite from "../../../images/logo-white-horizontal.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LandingFooterLinks from "../LandingFooterLinks/LandingFooterLinks";
import localeText from "../../../../locale";

const LandingFooter = () => {
  const cards = [
    {
      icon: "pi pi-phone",
      title: localeText("call"),
      description: localeText("callDescription"),
    },
    {
      icon: "pi pi-whatsapp",
      title: localeText("buyInWhatsapp"),
      description: localeText("buyInWhatsappDescription"),
    },
    {
      icon: "pi pi-headphones",
      title: localeText("assistance"),
      description: localeText("assistanceDescription"),
    },
  ];

  return (
    <footer className="landing full-width" style={{ backgroundColor: "#1E1B4A", color: "#FAFBFC" }}>
      <section className="py-5">
        <div className="grid m-0">
          <div className="col-12 flex flex-column gap-4 flex-order-1 md:flex-order-0  mt-6 md:mt-0">
            <div className="text-md font-bold md:text-left text-center">
              {localeText("needHelp")}
            </div>
            <div className="flex flex-column gap-4 md:flex-row">
              {cards.map((card, index) => (
                <div
                  key={index}
                  className="card col flex items-center gap-3 border-round-lg p-3"
                  style={{ backgroundColor: "#1E1B4A", border: "1px solid #D9E3FF" }}
                >
                  <Avatar
                    style={{ backgroundColor: "#838EF626", color: "#D9E3FF" }}
                    icon={card.icon}
                    size="large"
                  />
                  <div className="flex flex-column justify-content-center">
                    <div className="text-lg font-bold" style={{ color: "#D9E3FF" }}>
                      {card.title}
                    </div>
                    <div className="text-sm font-regular" style={{ color: "#838EF6" }}>
                      {card.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-12 md:col-6 flex flex-column gap-3 md:gap-8 md:text-left text-center md:mt-8">
            <div>
              <img src={logoWhite} alt="app-logo" style={{ width: "100%", maxWidth: "362px" }} />
            </div>
            <div>
              <p className="text-md font-medium">{localeText("followUs")}</p>
              <div className="flex gap-4 justify-content-center md:justify-content-start">
                <a
                  href="https://www.facebook.com/people/Extraconomy/61566025845885/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="pi pi-facebook" style={{ fontSize: "2em", color: "#D9E3FF" }}></i>
                </a>
                <a href="https://www.instagram.com/extraconomy_official/#" target="_blank" rel="noreferrer">
                  <i className="pi pi-instagram" style={{ fontSize: "2em", color: "#D9E3FF" }}></i>
                </a>
                <a href="https://www.tiktok.com/@extraconomy" target="_blank" rel="noreferrer">
                  <i className="pi pi-tiktok" style={{ fontSize: "2em", color: "#D9E3FF" }}></i>
                </a>
                <a href="https://www.linkedin.com/company/extraconomy/" target="_blank" rel="noreferrer">
                  <i className="pi pi-linkedin" style={{ fontSize: "2em", color: "#D9E3FF" }}></i>
                </a>
                <a href="https://x.com/extraconomy" target="_blank" rel="noreferrer">
                  <i className="pi pi-twitter" style={{ fontSize: "2em", color: "#D9E3FF" }}></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 flex-order-3 grid gap-2 md:mt-8">
            <LandingFooterLinks />
          </div>
        </div>
      </section>
    </footer>
  );
};

export default LandingFooter;
