const planIntroTextDictES = {
  // Partner Intro
  registerAsBusinessPartner: "Registrar como Partner",
  businessPartnerDescriptionPt1:
    "Un Partner puede ofrecer bienes y servicios en nuestra plataforma. Por una tarifa de registro de ",
  businessPartnerDescriptionPt2:
    "conviertase en un Partner y trabaje con nosotros para recibir los siguientes benefícios:",
  instantlyEarn: "Gana al instante ",
  worthOfExtracredit: " de créditos Extracredit (+ ",
  listBusinessPartner1:
    "si fue uno de los primeros 1000 miembros en su país en registrarse como Partner)",
  listBusinessPartner2:
    "Obtenga su escaparate personal para ofrecer o recibir productos y servicios",
  businessPartnerLearnMore: " sobre los beneficios de ser un Partner",
  alreadyABusinessPartnerPt1: "Usted es dueño de una empresa? Considere el plan de ",
  alreadyABusinessPartnerPt2: " más adecuado para personas jurídicas.",
  // Manager Intro
  registerAsAreaManager: "Registrarse como Manager",
  textManager1: "Plan de ventas adquirido: GeoGold",
  textManager2: "Adecuado para personas jurídicas y grandes empresas.",
  textManager3:
    "Con este perfil usted podrá ofrecer consultoría y vender y/o comprar bienes y servicios en nuestra plataforma.",
  textManager4: "PERFIL GEOLOCALIZADO",
  textManager5: "Ganas inmediatamente",
  textManager6:
    "de Extrabonus para utilizar como descuento en las distintas acciones de marketing que puedes realizar en la plataforma para aumentar tu visibilidad y tus ventas.",
  textManager7:
    "Consigue tu propia tienda online geolocalizada para ofrecer productos y servicios con publicidad ilimitada",
  textManager8: "Puedes insertar videos en tus anuncios de ventas.",
  textManager9:
    "Podrás crear automáticamente tu círculo económico de clientes, vendedores y promotores a través de ventas y geolocalización",
  textManager10:
    "Recibirás recompensas por cada actividad realizada en tu círculo económico y en función del desarrollo territorial.",
  textManager11: "Por una inscripción de sólo",
  textManager12: "recibirás los siguientes beneficios:",

  areaManagerDescriptionPt1:
    "Manager es el plan más ventajoso y rentable ofrecido por nuestra plataforma. Por una tarifa de inscripción de ",
  areaManagerDescriptionPt2:
    "puede inscribir la su empresa para trabajar con nosotros y recibir los siguientes benefícios:",
  listAreaManager1:
    "si fue uno de los primeros 1000 miembros en su país a registrar su empresa como Manager)",
  listAreaManager2:
    "Adquiera su espacio de e-commerce para dar a conocer y vender sus productos y servicios",
  listAreaManager3: "Reciba automáticamente nuevos clientes en tu círculo económico",
  areaManagerLearnMore: "  sobre las muchas ventajas de ser un Manager",
  // Customer Intro
  registerAsSpecialCustomer: "Registrarse como Customer",
  specialCustomerDescription:
    "Comience a comprar bienes y servicios mientras recibe siempre reembolsos.",
  specialCustomerDescription2:
    "Utilice sus créditos para nuevas compras o conviértalos en dinero o servicios a través de nuestro programa de fidelidad Extrapay.",
  specialCustomerDescription3:
    "Descargue nuestra App para registrarse y reciba inmediatamente un crédito promocional para usar en sus primeras compras.",
  listSpecialCustomer1: "¡Incluso puedes vender!",
  listSpecialCustomer2: "¡Sube tus primeros anuncios gratuitos y comienza a ganar!",
  listSpecialCustomer3Pt1: "Descarga nuestra App oficial para registrarte y ganar ",
  listSpecialCustomer3Pt2: "de créditos Extracredit gratuitamente.",
  alreadyASpecialCustomer: "Ya eres uno Customer? Considere actualizar su plan para ",
  or: " o ",
  taxExplanation: "* Impuestos sobre las ventas según su País.",
  // Promoter Intro
  registerAsSalePromoter: "Registrarse como Promoter",
  customerLearnMore: "sobre el Customer",
  // Seller Intro
  registerAsSeller: "Registrarse como Seller",
  acquiredSalesPlanStartSeller: "Plan de ventas adquirido: StartSeller",
  desc1Seller:
    "Adecuado para personas físicas/jurídicas, servicios, medianas empresas y dropshippers",
  desc2Seller: "Con este perfil podrá vender y/o comprar bienes y servicios en nuestra plataforma.",
  desc3Seller: "Por una inscripción de sólo",
  desc4Seller: "recibirás los siguientes beneficios:",
  desc5Seller: "Ganas inmediatamente",
  desc6Seller:
    "de Extrabonus para utilizar como descuento en las distintas acciones de marketing que puedes realizar en la plataforma para aumentar tu visibilidad y tus ventas.",
  desc7Seller:
    "Consigue tu propio e-commerce personal para ofrecer productos y servicios con anuncios ilimitados",
  desc8Seller: "¿Tienes un negocio? Considere el perfil de",
  desc9Seller: "más adecuado para personas jurídicas y grandes empresas.",
  desc10Seller:
    "Puoi creare il tuo circolo economico di clienti, venditori and promotori atraverse le tue attività di vendita e consulenza",
  desc11Seller: "Riceverai ricompense per ogni attività svolta nel tuo circolo economico",
  desc12Seller:
    "Este es un PERFIL GEOLOCALIZADO que verifica automáticamente a los nuevos clientes. ¿Cómo? El usuario que escriba en la App ampliará el círculo económico del gerente y continuará aumentando la posibilidad de generar ingresos y créditos adicionales.",
  alreadyAsSeller: "Ya es un Seller? Considere actualizar su plan para",
  AcquiredSalesPlan: "Adquirir un plan de ventas",
  suitableForIndividualsServicesAndSmallBusinesses:
    "Adecuado para Personas Físicas, Servicios y Pequeñas Empresas",
  discorverPlus: "sobre todas las oportunidades de ser Manager!",
  descPartner:
    "Con este perfil estará habilitado para ofrecer consultoría y vender y/o adquirir bienes y servicios en nuestra plataforma.",
  register1: "Ganas inmediatamente",
  register2:
    " de Extrabonus para utilizar como descuento en las distintas acciones de marketing que puedes realizar en la plataforma para aumentar tu visibilidad y tus ventas.",
  textpartner2:
    "Consigue tu escaparate personal para ofrecer productos y servicios hasta 50 anuncios al año",
  textpartner3:
    "Puedes crear tu propio círculo económico de clientes, vendedores y promotores a través de tus actividades de ventas y consultoría",
  textpartner4: "Você receberá recompensas por cada atividade realizada em seu círculo econômico",
  NoMonthlyPaymentRequired: "Nessuna mensilità richiesta.",
  AnnualRenewal: "Renovación anual",
  // promoter
  suitableFor:
    "Adecuado para personas físicas, consultores y afiliados comerciales, entidades legales.",
  promoterResgisterText1:
    "Con este perfil estarás capacitado para ofrecer consultoría y desarrollar la red de empresas y particulares.",
  promoterResgisterText2: "En la membresía gratuita obtendrás:",
  promoterResgisterText3_1: "Inmediatamente",
  promoterResgisterText3_2:
    "de crédito extra para utilizar como descuento en tus compras en la plataforma.",
  promoterResgisterText4: "Um contrato de representante de vendas.",
  promoterResgisterText5:
    "Puedes crear tu propio círculo económico de clientes y vendedores a través de tus actividades de consultoría.",
  promoterResgisterText6:
    "Recibe comisiones en efectivo y créditos por cada actividad directa e indirecta realizada en tu círculo económico.",
  promoterResgisterText7: "Se requiere cuota mensual de capacitación y actividades.",
  promoterResgisterText8: "No se requiere renovación anual.",
  promoterResgisterText9: "Descubre más sobre las ventajas de ser Promotor",
  promoterResgisterText10: "¿Quieres vender también? Considere los planes de ventas de",
  register3:
    "de Extrabonus para utilizar como descuento en las distintas acciones de marketing que puedas realizar en la plataforma para aumentar tu visibilidad y ventas.",
};

export default planIntroTextDictES;
