import React, { useState } from "react";

const AccordionItem = ({ title, content, isOpen, onToggle, index }) => {
  return (
    <div
      className="mb-2 card py-4 px-5 border-round-lg"
      style={{ border: isOpen ? "1px solid #433AC8" : "none" }}
    >
      <div
        className="w-full flex justify-content-between cursor-pointer align-items-center bg-white "
        onClick={onToggle}
      >
        <span className="flex align-items-center gap-4">
          <span className="font-medium text-lg" style={{ color: "#433AC8" }}>
            0{index + 1}
          </span>
          <span className=" text-md" style={{ color: isOpen ? "#433AC8" : "#26235E" }}>
            {title}
          </span>
        </span>
        <span
          className={`align-self-center transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        >
          <i className="pi pi-chevron-down" style={{ color: "#505F79" }}></i>
        </span>
      </div>
      <div className={`px-2 overflow-hidden ${isOpen ? "  pt-4" : "max-h-0"}`}>
        <div style={{ color: "#344563" }}>{content}</div>
      </div>
    </div>
  );
};

const LandingAccordionComponent = (questions) => {
  const [openItems, setOpenItems] = useState(new Set());

  const toggleItem = (id) => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(id)) {
      newOpenItems.delete(id);
    } else {
      newOpenItems.add(id);
    }
    setOpenItems(newOpenItems);
  };

  return (
    <div className="w-full mx-auto">
      {questions.questions.map((question, index) => (
        <AccordionItem
          key={question.id}
          index={index}
          title={question.title}
          content={question.content}
          isOpen={openItems.has(question.id)}
          onToggle={() => toggleItem(question.id)}
        />
      ))}
    </div>
  );
};

export default LandingAccordionComponent;
