import { Avatar } from "primereact/avatar";
import economyIcon from "../../../images/landing/economy-icon.svg";
import placesIcon from "../../../images/landing/places-icon.svg";
import cashbackIcon from "../../../images/landing/cashback-icon.svg";
import prevIcon from "../../../images/landing/prev-icon.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./style.css";
import localeText from "../../../../locale";

const SectionTwoAboutCard = () => {
  return (
    <div className="card mb-0 border-none card-about-shadow">
      <div className="ruled-grid">
        <div className="flex flex-column gap-2 col-12  grid-card">
          <div>
            <Avatar
              className="bg-white"
              image={placesIcon}
              size="medium"
              style={{ color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#344563" }}>
            {localeText("geolocalization")}
          </div>
          <div className="text-sm" style={{ color: "#7A869A" }}>
            {localeText("geolocalizationDescription")}
          </div>
          <Link to="/benefits" className="text-md font-medium" style={{ color: "#208C56" }}>
            {localeText("learnMore")}
          </Link>
        </div>
        <div className="flex flex-column gap-2 col-12  grid-card">
          <div>
            <Avatar
              className="bg-white"
              image={economyIcon}
              size="medium"
              style={{ color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#344563" }}>
            {localeText("coperativeEconomy")}
          </div>
          <div className="text-sm" style={{ color: "#7A869A" }}>
            {localeText("coperativeEconomyDescription")}
          </div>
          <Link to="/benefits" className="text-md font-medium" style={{ color: "#208C56" }}>
            {localeText("learnMore")}
          </Link>
        </div>
        <div className="flex flex-column gap-2 col-12  grid-card">
          <div>
            <Avatar
              className="bg-white"
              image={cashbackIcon}
              size="medium"
              style={{ color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#344563" }}>
            {localeText("cashback")}
          </div>
          <div className="text-sm" style={{ color: "#7A869A" }}>
            {localeText("cashbackDescription")}
          </div>
          <Link to="/benefits" className="text-md font-medium" style={{ color: "#208C56" }}>
            {localeText("learnMore")}
          </Link>
        </div>
        <div className="flex flex-column gap-2 col-12  grid-card">
          <div>
            <Avatar
              className="bg-white"
              image={prevIcon}
              size="medium"
              style={{ color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#344563" }}>
            {localeText("pension")}
          </div>
          <div className="text-sm" style={{ color: "#7A869A" }}>
            {localeText("pensionDescription")}
          </div>
          <Link to="/benefits" className="text-md font-medium" style={{ color: "#208C56" }}>
            {localeText("learnMore")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoAboutCard;
