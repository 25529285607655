const planIntroTextDictEN = {
  // Partner Intro
  registerAsBusinessPartner: "Register as a Partner",
  businessPartnerDescriptionPt1:
    "A Partner can provide goods and services in our platform. For a fee of ",
  businessPartnerDescriptionPt2:
    "become a Partner and work with us to receive the following benefits:",
  instantlyEarn: "Instantly earn ",
  worthOfExtracredit: " worth of Extracredit credits (+ ",
  listBusinessPartner1: "if you're one of the first 1000 of a country to register as a Partner)",
  listBusinessPartner2: "Get your personal showcase to offer or receive products and services",
  businessPartnerLearnMore: " about the benefits of being a Partner",
  alreadyABusinessPartnerPt1: "Do you own a company? Consider subscribing to the ",
  alreadyABusinessPartnerPt2: " plan, the most suitable for companies.",
  // Manager Intro
  registerAsAreaManager: "Register as an Manager",
  textManager1: "Acquired sales plan: GeoGold",
  textManager2: "Suitable for legal entities and large businesses",
  textManager3:
    "With this profile you will be enabled to offer consultancy and sell and/or purchase goods and services on our platform.",
  textManager4: "GEOLOCALIZED PROFILE",
  textManager5: "You immediately earn ",
  textManager6:
    "of Extrabonus to be used as a discount in the various marketing actions that you can carry out on the platform to increase your visibility and sales.",
  textManager7:
    "Get your own geolocalized online store to offer products and services with unlimited ads",
  textManager8: "You can insert videos into your sales ads",
  textManager9:
    "You can automatically create your economic circle of customers, sellers and promoters through sales and geolocation",
  textManager10:
    "You will receive rewards for every activity carried out in your economic circle and based on territorial development",
  textManager11: "For a registration of only",
  textManager12: "you will receive the following benefits:",
  areaManagerDescriptionPt1:
    "Manager is the most benefitial and profitable plan offered by our platform. For a fee of ",
  areaManagerDescriptionPt2:
    "you can register your company to work with us and receive the following benefits:",
  listAreaManager1:
    "if you're one of the first 1000 of a country to register your company as an Manager)",
  listAreaManager2: "Get your e-commerce space to make your products and services known and sold",
  listAreaManager3: "Automatically receive new customers in your economic circle",
  areaManagerLearnMore: " about the many benefits of being an Manager",
  // Customer Intro
  registerAsSpecialCustomer: "Register as a Customer",
  specialCustomerDescription:
    "Start purchasing goods and services while always receiving cashback.",
  specialCustomerDescription2:
    "Use your credits for new purchases or convert them into money or services through our loyalty program Extrapay.",
  specialCustomerDescription3:
    "Download our App to sign up and immediately receive a promotional credit to use on your first purchases.",
  listSpecialCustomer1: "You can even sell!",
  listSpecialCustomer2: "Upload your first free ads and start earning!",
  listSpecialCustomer3Pt1: "Download our official App to Sign up and earn ",
  listSpecialCustomer3Pt2: "of Extracredit credits free of charge.",
  alreadyASpecialCustomer: "Already a Customer? Consider upgrading your plan to ",
  or: " or ",
  taxExplanation: "* Sales tax in accordance with your Country.",
  // Promoter Intro
  registerAsSalePromoter: "Register as a Promoter",
  customerLearnMore: "about the Customer",
  // Seller Intro
  registerAsSeller: "Register as a Seller",
  acquiredSalesPlanStartSeller: "Acquired sales plan: StartSeller",
  desc1Seller:
    "Suitable for natural/legal persons, services, medium-sized businesses and drop-shippers",
  desc2Seller:
    "With this profile you will be enabled to sell and/or purchase goods and services on our platform.",
  desc3Seller: "For a registration of only",
  desc4Seller: "you will receive the following benefits:",
  desc5Seller: "You immediately earn",
  desc6Seller:
    "of Extrabonus to be used as a discount in the various marketing actions that you can carry out on the platform to increase your visibility and sales.",
  desc7Seller: "Get your own personal e-commerce to offer products and services with unlimited ads",
  desc8Seller: "Do you have a business? Consider the",
  desc9Seller: "profile more suitable for legal entities and large businesses.",
  desc10Seller:
    "You can create your economic circle for clients, sellers and promoters across your sales and consultation activities.",
  desc11Seller: "You will receive rewards for every activity performed in your economic circle.",
  desc12Seller:
    "This is a GEOLOCALIZZATO PROFILE and automatically verifies new clients. How? The user who will write to the App will increase the manager's economic circle and continue to increase the possibility of earning revenue and credit aggiuntivi.",
  alreadyAsSeller: "Already a Seller? Consider upgrading your plan to",
  AcquiredSalesPlan: "Acquire a sales plan",
  suitableForIndividualsServicesAndSmallBusinesses:
    "Suitable for individuals, services and small businesses",
  discorverPlus: "about all the opportunities of being a Manager!",
  descPartner:
    "With this profile you will be able to offer consultancy and sell and/or acquire goods and services in our platform.",
  register1: "Immediately earn",
  register2:
    "of Extrabonus to use as a discount in the various marketing actions you can perform on the platform to increase your visibility and sales.",
  textpartner2: "Get your personal showcase to offer products and services up to 50 ads per year",
  textpartner3:
    "You can create your own economic circle of customers, sellers and promoters through your sales and consultancy activities",
  textpartner4: "You will receive rewards for every activity performed in your economic circle",
  NoMonthlyPaymentRequired: "No monthly payment required.",
  AnnualRenewal: "Annual renewal",
  // promoter
  suitableFor: "Suitable for individuals, consultants and commercial affiliates, legal entities",
  promoterResgisterText1:
    "With this profile you will be enabled to offer consultancy and develop the network of companies and individuals.",
  promoterResgisterText2: "In the free membership you will get:",
  promoterResgisterText3_1: "Immediately",
  promoterResgisterText3_2:
    "of Extracredit to use as a discount on your purchases on the platform.",
  promoterResgisterText4: "A sales representative contract.",
  promoterResgisterText5:
    "You can create your own economic circle of customers and sellers through your consulting activities",
  promoterResgisterText6:
    "Receive commissions in cash and credits for every direct and indirect activity carried out in your economic circle",
  promoterResgisterText7: "Training and activities monthly fee required.",
  promoterResgisterText8: "No annual renewal required.",
  promoterResgisterText9: "Find out more about the advantages of being a Promoter",
  promoterResgisterText10: "Do you want to sell too? Consider",
  register3: "of Extrabonus to use as a discount in the various marketing actions that you can carry out on the platform to increase your visibility and your sales"
};

export default planIntroTextDictEN;
