import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import WorkWithUs from "../../../images/landing/work-with-us.png";
import "./style.css";
import localeText from "../../../../locale";

const LandingWorkWithUs = () => {
  return (
    <section className="pt-6 md:py-8 full-width" style={{ backgroundColor: "#17633D" }}>
      <div className="grid align-items-center ">
        <div className="col-12 md:col-6 flex flex-column gap-6">
          <div style={{ color: "#FFFFFF" }}>
            <div className="flex flex-column gap-3">
              <div className="font-regular text-md">{localeText("workWithUsSubtitle")}</div>
              <div className="font-bold text-4xl line-height-2 title" style={{ color: "#FFC839" }}>
                {localeText("workWithUsTitle")}
              </div>
            </div>
          </div>
          <div className="font-regular text-lg" style={{ color: "#ACE6B8" }}>
            {localeText("workWithUsDescription")}
          </div>
          <div>
            <Link
              className="font-semibold text-lg text-white"
              style={{ textDecoration: "underline" }}
              to="/salepromoter"
            >
              {localeText("doYouWantToWork")}
            </Link>
          </div>
        </div>
        <div className="col-12 md:col-6 w-vw md:w-100 ">
          <div
            className="h-full relative"
            style={{
              overflow: "hidden",
              backgroundImage: `url(${WorkWithUs})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "602px",
              width: "100%",
            }}
          >
            <div className="p-4 card-blur-wr card-blur-wr-1 m-0 border-round-lg border-none text-white flex flex-column gap-1">
              <div className="font-bold text-2xl title">+ Extracredit</div>
              <div className="text-xs opacity-70">{localeText("extracredits")}</div>
            </div>
            <div className="p-4 card-blur-wr card-blur-wr-2 m-0 border-round-lg border-none text-white flex flex-column gap-1">
              <div className="font-bold text-2xl title">+ {localeText("moreBonus")}</div>
              <div className="text-xs opacity-70">{localeText("moreBonusesDescription")}</div>
            </div>
            <div className="p-4 card-blur-wr card-blur-wr-3 m-0 border-round-lg border-none text-white flex flex-column gap-1">
              <div className="font-bold text-2xl title">{localeText("recurrence")}</div>
              <div className="text-xs opacity-70">
                {localeText("recurrenceDescription")}
              </div>
            </div>
            <div className="p-4 card-blur-wr card-blur-wr-4 m-0 border-round-lg border-none text-white flex flex-column gap-1">
              <div className="font-bold text-2xl title">{localeText("comissions")}</div>
              <div className="text-xs opacity-70">{localeText("comissionsDescription")}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingWorkWithUs;
