const newProductDictES = {
  newProductcategories: "Categorías",
  subcategories: "Subcategorías",
  next: "Siguiente",
  previous: "Volver",
  clickAndSelect:
    "Pulse un elemento para seleccionar, pulse de nuevo para desistir de la selección",
  noSubcategories: "No hay subcategorías para la categoría seleccionada.",
  pleaseSelectCategory: "Por favor elija una categoría antes de continuar.",
  pleaseSelectSubcategory: "Por favor elija una subcategoría antes de continuar.",
  addNewProduct: "Añadir producto o servicio a la vitrina",
  productInfo: "Información del producto",
  productFields: "rellene los campos con la información del producto",
  promotionalPrice: "Precio con descuento",
  percentExtracoin: "Porcentaje de crédito",
  dragImgs: "Arrastra hasta 10 imágenes aquí",
  uploadImages: "Enviar Imagen",
  selectProductState: "Seleccione el estado del producto",
  pleaseChooseAtLeastOne: "Por favor elija al menos una imagen.",
  youCanOnlyChooseUpTo5: "Usted sólo puede elegir  hasta 10 imágenes.",
  pleaseUploadAtLeastOne: "Elija al menos una imagen.",
  successUploadImage: "Imagen del producto enviada con éxito.",
  productPreview: "Vista previa del producto",
  pressRegisterOrPublish:
    'Presione el botón "Registrar" para registrarse sim publicar (el producto sólo será visíble en su lista de productos).',
  pressRegisterAndPublish:
    'Presione el botón "Registrar y Publicar" para registrarse y hacer que su producto sea visíble para todos.',
  pressRegisterPublishAndNotify:
    'Presione el botón "Registrar, Publicar y Notificar" para registrarse, hacer que su producto sea visíble para todos y notificar a los usuarios del sistema sobre su nuevo producto.',
  yourMinimumExtracoinPercentage: "El porcentaje mínimo de Extracredit del su plan es",
  buy: "Comprar",
  buyNow: "Comprar ahora",
  register: "Registrar",
  registerAndPublish: "Registrar y Publicar",
  registerPublishAndNotify: "Registrar, Publicar y Notificar",
  notifyToYourEconomicCircle:
    "La notificación puede ser enviada a todos los usuarios de su círculo económico, cuesta",
  notifyAll: "Notificar a todos los usuarios del sistema, cuesta",
  insufficientCredits: "Créditos insuficientes",
  unexpectedErrorRegisterProduct: "Error inesperado al registrar el producto.",
  unexpectedErrorUploadFile: "Error inesperado al cargar el archivo.",
  percentExtracoinExplanationPt1: "Usted puede pagar hasta",
  percentExtracoinExplanationPt2: "del precio deste elemento en créditos Extracredit",
  brand: "Marca",
  selectBrand: "Seleccione la marca del su producto",
  type: "Tipo",
  selectType: "Seleccione el tipo de producto",
  gender: "Género",
  selectGender: "Seleccione el género",
  model: "Modelo",
  doYouWantDiscount: "Quieres dar un precio con descuento?",
  promotionalPriceMustBeLessThan:
    "El precio con descuento deve ser más barato que el precio regular.",
  variationMustHaveAValidPrice: "La variación debe tener un precio válido.",
  sendSeller: "Soy responsable del envío del producto.",
  addToCart: "Añadir al carrito",
  available: "unidad(es) disponible(s)",
  shippingInfo: "Envío",
  byBuyer: "Comprador",
  bySeller: "Vendedor",
  productImages: "Imágenes del producto",
  previewImages: "Imágenes de vista previa",
  uploadProductFile: "Cargue el archivo real de su producto descargable:",
  uploadProductSampleFile:
    "Cargue un archivo de vista previa que estará disponible públicamente en la página del producto:",
  clickForASample: "Haga clic aquí para ver una muestra de este producto",
  subheading: "Subtítulo",
  heading: "Encabezamiento",
  variationsExplanation:
    "Puede configurar variaciones para este producto en el menú de lista de productos.",
  relatedProducts: "Productos Relacionados",
  productTechnicalInfo: "Informaciones Técnicas",
  productTechnicalInfoExplanation:
    "Puede proporcionar hasta 5 campos de información técnica sobre su producto",
  fieldName: "Nombre del campo",
  fieldValue: "Valor del campo",
  errorWhileRegisteringTechnicalInfo: "Error al registrar la informaciones técnicas.",
  useShippingService:
    "¿Usar el servicio de envío? (Deberá proporcionar las dimensiones de sus productos)",
  height: "Altura",
  width: "Ancho",
  length: "Longitud",
  weight: "Peso",
  shippingUnavailableWarning: "Aviso: servicio de carga no disponible",
  WeDoNotYetHaveShipping:
    "Todavía no tenemos una cuota de envío para los remitentes fuera de Italia, es decir, los productos que necesitan una cuota de envío no pueden ser enviados por nuestro servicio de envío.",
  freightService: "Servicio de carga:",
  freightServiceAvaible: "Disponible",
  freightServiceUnavailable: "No disponible",
  service: "Servicio",
  downloadable: "Descargable",
  typeOfProduct: "Tipo de producto",
  pleaseSelectTypeProduct: "Por favor seleccione el tipo de producto.",
  weAreWorkingInThisFeature: "Estamos trabajando en esta funcionalidad.",
  chooseWeightRange: "Elija el rango de peso de su producto",
  freight: "Carga",
  credits: "créditos",
  notifyCircleExplanationt1:
    "La notificación sólo se envía a los usuarios de su círculo económico al costo de",
  notifyCircleExplanationt2: "que se deducirán de su cartera Extrabonus.",
  notifyAllExplanation1:
    "La notificación se envía a todos los usuarios de la plataforma al costo de",
  notifyAllExplanation2: " que se deducirán de su cartera Extrabonus.",
  additionalShippingPriceProduct: "Precio de envío adicional del producto",
  without: "Sin",
  free2: "Gratis",
  paid: "A Pagar",
  includesTax: "El costo de envío desde nuestra plataforma se incluirá en el valor del producto.",
  youPayFreight: "Usted es responsable del envío del producto",
  banggoodPaysFreight: "Banggood es responsable del envío del producto",

  paidFreight: "El cliente paga el envío",
  lookingTheBestShippingMethod: "Buscamos el mejor método de envío.",
  finalPrice: "Precio estimado",
  priceCalculator: "Calculadora de precios",
  or: "o",
  valueCanNotBeNegative: "El valor no puede ser negativo.",
  paidOrWithdrawn: "A Pagar o retirado en el lugar",
  paidOrWithdrawnExplain: "El cliente paga el envío o retira en el lugar",
  freightValueExplain:
    "El coste de envío se descontará del valor final del producto para repercutirlo gratuitamente al cliente.",
  freightPriceGreaterThanPrice: "El valor del producto no puede ser menor que el costo de envío.",
  shippingIncluded: "Envío incluido",
  priceCharged: "Precio cobrado",
  amountToBeReceived: "Valor a recibir",
  chooseWeightRangeToCalculateShipping: "Elija el rango de peso para calcular el envío.",
  discounts: "Descuentos",
  included: "Incluido (Con nuestro envío)",
  includedWithoutSendcloud: "Incluido (Sin nuestro envío)",
  theCustomerWillPay: "El cliente pagará",
  ifYouWishToReceive: "Si desea recibir",
  additionals: "Adicionales",
  thenYouWillHaveToCharge: "Entonces tendrá que cobrar",
  condition: "Condición",
  selectCondition: "Seleccione la condición del producto",
  labeled: "Etiquetado",
  notLabeled: "No etiquetado",
  excellent: "Excelente",
  good: "Bueno",
  discrete: "Discreto",
  stockGreaterThanZero: "El stock debe ser mayor que cero.",
  minimunValueError:
    "El volumen mínimo es de 100 centímetros cúbicos. Cambie la longitud, anchura o altura.",
  simulateLeastOneShippingValue: "Simule al menos un valor de envío.",
  enterZipCodeToSimulateEndCustomer:
    "Ingrese un código postal para simular al cliente final y agregar valor al producto. El coste de envío se descontará del valor del producto en el momento de la compra.",
  simulate: "Simular",
  addValidPostalCode: "Añadir un código postal válido",
  withOurFright: "Con nuestro envío",
  withoutOurFright: "Sin nuestro envío",
  youHaveExceededThe5PublishedProducts: "Has superado el límite de 5 productos publicados.",
  youHaveExceededThe5PublishedProductsExplanation:
    "Para publicar más productos, elimine uno de los productos publicados o actualice su plan de membresía.",
  youCannotPublishProductsYet: "No puedes publicar productos todavía.",
  firstCreateYourStore: "Primero crea tu tienda.",
  populateCashback: "Rellene el porcentaje de cashback",
  yourProfile: "Tu perfil de",
  canOfferACashbackOf: "puede ofrecer un cashback del 1% hasta",
  until: "hasta",
  possibleCashbackAmountsIn: "Posibles valores de cashback en",
  wantToGivePercentageBeacuseShare: "¿Quieres dar un porcentaje de cashback?",
  specifyThePercentageOfThePersonWhoShares: "Especifique el porcentaje de la persona que comparte",
  takePicture: "Tomar foto",
  capturedImage: "Imagen capturada",
  takeAnother: "Tomar otra",
  save: "Guardar",
  apply: "Aplicar",
  finalPriceForClient: "Precio final para el cliente",
};

export default newProductDictES;
