const statementTableDictPT = {
  walletStatement: "Extrato da carteira",
  extracoinStatement: "Extrato de Extracredit",
  extrabonusStatement: "Extrato Bonus Ads",
  bonusStatement: "Extrato da renda diária vitalícia",
  from: "De",
  toFrom: "Até",
  check: "Ver",
  statement: "Extrato",
  description: "Descrição",
  status: "Status",
  dateTransaction: "Data da Transação",
  emptyDates: "Por favor escolha um período de duas datas.",
  completed: "Completa",
  pending: "Pendente",
  pendingVoucher: "Voucher pendente",
  canceled: "Cancelada",
};

export default statementTableDictPT;
