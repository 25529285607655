import React from "react";
import { Avatar } from "primereact/avatar";
import digitalSubIcon from "../../../images/landing/digital-sub-icon.svg";
import moneyHeaderIcon from "../../../images/landing/money-header-icon.svg";
import renegotiationIcon from "../../../images/landing/renegotiation-icon.svg";
import transferIcon from "../../../images/landing/transfer-icon.svg";
import localeText from "../../../../locale";

export default function LandingAboutSectionOne() {
  return (
    <section className="py-2 md:py-8" id="about">
      <div
        className="flex flex-column align-items-center justify-content-center text-center gap-4 px-2 py-6 md:p-3 md:pb-7"
        style={{ maxWidth: "786px", margin: "0 auto" }}
      >
        <div className="text-5xl font-bold line-height-2 title" style={{ color: "#208C56" }}>
          {localeText("empowerYourBusiness")}
        </div>
        <div className="text-lg font-medium" style={{ color: "#7A869A" }}>
          {localeText("empowerYourBusinessDescription")}
        </div>
      </div>
      <div className="grid gap-3 md:gap-5 align-items-top justify-content-around py-6 md:py-4">
        <div className="flex flex-column gap-2 col-12 md:col" style={{ margin: "0 auto" }}>
          <div>
            <Avatar
              image={digitalSubIcon}
              size="xlarge"
              className="p-2"
              style={{ backgroundColor: "#F1FBF7", color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#091E42" }}>
            {localeText("comercialIntegration")}
          </div>
          <div className="text-md" style={{ color: "#7A869A" }}>
            {localeText("comercialIntegrationDescription")}
          </div>
        </div>
        <div className="flex flex-column gap-2 col-12 md:col" style={{ margin: "0 auto" }}>
          <div>
            <Avatar
              image={moneyHeaderIcon}
              size="xlarge"
              className="p-2"
              style={{ backgroundColor: "#F1FBF7", color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#091E42" }}>
            {localeText("powerfulEconomy")}
          </div>
          <div className="text-md" style={{ color: "#7A869A" }}>
            {localeText("powerfulEconomyDescription")}
          </div>
        </div>
        <div className="flex flex-column gap-2 col-12 md:col" style={{ margin: "0 auto" }}>
          <div>
            <Avatar
              image={renegotiationIcon}
              size="xlarge"
              className="p-2"
              style={{ backgroundColor: "#F1FBF7", color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#091E42" }}>
            {localeText("instantProfit")}
          </div>
          <div className="text-md" style={{ color: "#7A869A" }}>
            {localeText("instantProfitDescription")}
          </div>
        </div>
        <div className="flex flex-column gap-2 col-12 md:col" style={{ margin: "0 auto" }}>
          <div>
            <Avatar
              image={transferIcon}
              size="xlarge"
              className="p-2"
              style={{ backgroundColor: "#F1FBF7", color: "#208C56" }}
            />
          </div>
          <div className="font-bold text-lg" style={{ color: "#091E42" }}>
            {localeText("generateCredit")}
          </div>
          <div className="text-md" style={{ color: "#7A869A" }}>
            {localeText("generateCreditDescription")}
          </div>
        </div>
      </div>
    </section>
  );
}
