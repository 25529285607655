const landingDictIT = {
  home: "Inizio",
  benefits: "Benefici",
  benefitsExplanation:
    "La nostra piattaforma è uno strumento moderno, facilmente accessibile e redditizio.",
  firstBenefit: "Maggiore integrazione commerciale",
  secondBenefit: "Economia più leggera e più potente",
  thirdBenefit: "Opportunità di profitto istantaneo e costante",
  fourthBenefit: "Generare credito",
  features: "Caratteristiche",
  howItWorks: "Come funziona",
  meetOurPlans: "Scopri i nostri piani",
  join: "I nostri piani",
  plans: "Piani",
  login: "Accedere",
  whatIsIt: "Il marketplace che ti premia sempre",
  whatIsItExplanation:
    "Extraconomy è una piattaforma on-line di economia cooperativa e partecipativa con un sistema di ricompense e di geolocalizzazioni che permette a privati e aziende di accrescere il proprio potere economico e la propria rete di clienti di forma automatica e progressiva, attraverso le varie attività svolte sul marketplace.",
  why: "Perchè?",
  greaterBusiness: "Maggiore integrazione aziendale",
  lighterPowerful: "Economia più leggera e più potente",
  instantProfit: "Opportunità di guadagno istantanea e costante per tutti",
  genCredit: "Genera credito",
  whyShould: "Perché dovrei aderire ad Extraconomy?",
  seeBelow: "Vedi sotto le risorse della nostra rete sociale ed economica:",
  extracredit: "Ottieni Crediti",
  extracoinExplanation:
    "Registrati dall'APP e guadagna Extracredit per acquistare e/o condividere con i tuoi amici.",
  indicate: "Guadagna Ricompense",
  youMayGain: "Indirizza gli utenti alla tua rete e guadagna sempre più crediti e denaro reale.",
  economicNetwork: "Circolo Economico",
  networkOfUsers:
    "Crea automaticamente una rete di utenti per acquistare e vendere prodotti e servizi.",
  transactionSafety: "Sicurezza delle Transazioni",
  ourPlatform:
    "La nostra piattaforma è robusta e sicura. Puoi acquistare, vendere e trasferire denaro in modo sicuro.",
  findEverything: "Trova tutto ciò di cui hai bisogno",
  weHavePowerfulTool:
    "Abbiamo un potente strumento per trovare il prodotto che desideri acquistare in pochi clic.",
  buyerReviews: "Recensioni degli Acquirenti",
  checkTheReviews:
    "Controlla le recensioni degli utenti che hanno acquistato il prodotto che stai cercando.",
  geolocation: "Geolocalizzazione",
  effectiveTools: "Strumenti efficaci per far crescere automaticamente il tuo circolo economico.",
  exceptionalTrading: "Affari eccezionali",
  saferMoreFrequent: "Scambi più sicuri, frequenti e vantaggiosi.",
  economicInfluence: "Influenza economica",
  sellFaster: "Con il Cashback velocizzi le vendite e acquisti più facilmente, guadagnando sempre.",
  howItWorksExplanation:
    "Ricevi premi per ogni attività che si svolge sulla piattaforma, sotto forma di denaro e crediti che possono essere utilizzati all'interno del sistema come complemento economico negli scambi commerciali.",
  joinExtraconomy: "Unisciti ad Extraconomy.",
  belowArePlans: "Di seguito sono riportati i tipi di piani disponibili sul nostro servizio.",
  contactWhatTele:
    "Per info sul progetto e sull' apertura di mercato per Partners e Managers in un Paese, scrivi presso i seguenti contatti di Whatsapp e Telegram.",
  learnMore: "Scopri di piu'",
  doubts: "Dubbi? ",
  contactToUs: "Contattaci.",
  contact: "Contatto",
  scanQRCode: "Scansiona il QR Code qui sotto per contattarci tramite WhatsApp/Telegram:",
  highlights: "In evidenza",
  by: " di ",
  video: "Video",
  introVideo: "Video introduttivo sulla nostra piattaforma:",
  backToHomePage: "Torna alla Home Page",
  qrCodeExplanation:
    "Per info sul progetto e sull'apertura di mercato per Partners e Managers in un Paese, scrivi presso i seguenti contatti di Whatsapp e Telegram:",
  socialMedia: "Social Media",
  rightsReserved: "Tutti i Diritti Riservati",
  checkApp: "Controlla la nostra app",
  customerDescription: "solo per fare acquisti",
  partnerDescription: "Per vendite e acquisti",
  managerDescription: "E-commerce + Acquisti",
  benefitsExtracoinSection: "Vantaggi per l'acquisto del nostro token Extracoin EXC",
  extracoinBenefitTitle1: "2.000 Extracoin: diventa Partner di Extraconomy!",
  extracoinBenefitDescription1:
    "I possessori di 2.000 Extracoin ricevono un buono gratuito per diventare Partner del Marketplace Extraconomy. Unisciti alla rivoluzione e partecipa attivamente alla definizione del futuro dell'economia digitale.",
  extracoinBenefitTitle2: "5.000 Extracoin: sblocca lo status di Seller su Extraconomy!",
  extracoinBenefitDescription2:
    "I possessori di 5.000 Extracoin vengono premiati con un voucher gratuito per diventare Sellers sulla piattaforma Extraconomy. Mostra i tuoi prodotti e servizi a un pubblico globale.",
  extracoinBenefitTitle3: "15.000 Extracoin: ottieni lo status di Manager della tua Extraconomy!",
  extracoinBenefitDescription3:
    "I possessori di 15.000 Extracoin ricevono un buono esclusivo per diventare Manager di Extraconomy. Immergiti nell'universo gestionale e contribuisci alla crescita dell'intero ecosistema.",
  voucherExtracoinText1: "Hai già un ",
  clickHere: "Clicca qui",
  andRegister: "e registrati",
  voucherEmptyError: "Il campo del voucher non può essere vuoto.",
  extracoinVoucherError: "Il voucher inserito non è valido.",
  startsWith: "Inizia con",
  contains: "Contiene",
  notContains: "Non contiene",
  endsWith: "Termina com",
  equals: "Equivale",
  notEquals: "Non uguali",
  noFilter: "Senza Filtro",
  joinTheLaunchpad: "Unisciti al Launchpad del nostro token Extracoin su Cryptosmart!",
  popupDesc:
    "Acquistando i token EXC ad un prezzo speciale, riceverai in cambio un voucher gratuito per aprire un negozio online sul marketplace Extraconomy, attiva la tua rendita giornaliera!",
  exclusiveBenefits: "Vantaggi esclusivi",
  popupPartnerTitle: "Diventa Partner!",
  popupPartnerDesc:
    "*Acquistando un minimo di 2.000 Extracoin riceverai in omaggio un voucher per aprire un punto vendita sul marketplace Extraconomy.",
  popupSellerTitle: "Sblocca il profilo del Seller!",
  popupSellerDesc:
    "*Acquistando almeno 5.000 Extracoin riceverai in omaggio un voucher per aprire un e-commerce sulla piattaforma Extraconomy.",
  popupManagerTitle: "Raggiungi lo stato di Manager!",
  popupManagerDesc:
    "*Acquistando un minimo di 15.000 Extracoin riceverai un voucher esclusivo per aprire un negozio online su Extraconomy con tutti i vantaggi inclusi.",
  promotionValidUntilEndLaunchpad: "Promozione valida fino alla fine del Launchpad",
  downloadAppAndEarn: "Scarica l'app ora e guadagna 20 euro di credito promozionali",
  frequentlyAskedQuestion: "Domande frequenti",
  faqTitle1: "La piattaforma Extraconomy è sicura?",
  faqAnswer1:
    "Sì, disponiamo di un team tecnico specializzato nella sicurezza delle informazioni. Tutti i contenuti, i dati e le operazioni finanziarie sulla piattaforma sono protetti da vari livelli di sicurezza, in questo modo possiamo mitigare il più possibile qualsiasi tipo di attacco informatico che possa verificarsi.",
  faqTitle2: "Come posso partecipare alla piattaforma?",
  faqAnswer2:
    "Se hai scoperto Extraconomy tramite qualcuno, loro sanno come farti entrare sulla piattaforma. In caso contrario, scarica la nostra App e iscriviti inserendo i tuoi dati per accedere direttamente allo Shop. Riceverai un credito di benvenuto da utilizzare nei tuoi futuri acquisti!",
  faqTitle3: "Ci sono spese di registrazione per poter vendere i miei prodotti o servizi?",
  faqAnswer3:
    "Sì, per avere uno spazio per la tua vetrina o per il tuo e-commerce esistono 3 piani di vendita con valori e vantaggi diversi a seconda delle esigenze di ciascun utente privato e aziendale. Puoi scoprire i Piani direttamente qui su questo sito.",
  faqTitle4:
    "Quando svolgo un'attività promozionale e di vendita sulla piattaforma Extraconomy ho un rapporto di lavoro?",
  faqAnswer4:
    "No, se hai aderito ad uno dei 3 piani di vendita sarai un lavoratore autonomo e pienamente responsabile delle tue spese. Solo se decidi di lavorare con noi come Promoter riceverai un contratto di Incaricato alle Vendite.",
  faqTitle5: "Come posso richiedere la cancellazione del mio account? ",
  faqAnswer5:
    "cancellare il tuo account Extraconomy dovrai semplicemente accedere nella tua Area Personale e poi su Modifica Profilo richiedere la cancellazione dell'account senza ulteriori costi. Oppure potrai inviare una email a support@extraconomyglobal.com utilizzando la stessa email che utilizzi per accedere a Extraconomy, con oggetto “Cancellazione Account”.",
  faqTitle6: "Sono nuovo sulla piattaforma, c'è qualche supporto? ",
  faqAnswer6:
    "Sì, abbiamo un supporto umanizzato e dedicato per aiutarti ogni volta che ne hai bisogno attraverso i canali di Whatsapp e Telegram che trovi tra i nostri contatti qui sul sito.",
  faqTitle7: "Ho fornito un'e-mail errata, e adesso?",
  faqAnswer7:
    "Per modificare la tua email, dovrai inviare una email a support@extraconomyglobal.com con l'oggetto “Cambia email”, comunicando tutti i dati di registrazione utilizzati al momento della registrazione e l'email corretta.",
  faqTitle8: "Quali sono le opzioni di pagamento?",
  faqAnswer8:
    "Attraverso PayPal, il nostro gateway di pagamento internazionale, puoi pagare il tuo ordine con carta di credito, carta di debito o conto bancario.",
  faqTitle9: "Quanto costa la spedizione nella mia regione?",
  faqAnswer9:
    "Per questo calcolo, il modo più semplice è simulare il tuo ordine. Scegli gli articoli con la Spedizione a Pagamento, aggiungili al carrello e completa l'acquisto, inserisci correttamente i tuoi dati (molto importante) e al termine dell'ordine il sito ti indicherà il costo di spedizione corretto. Se stai solo simulando, non fare clic su Checkout e non si tradurrà in alcun acquisto reale da parte tua. Modifica poi gli articoli e le quantità per vedere le differenze, sentiti libero.",
  faqTitle10: "Come funzionano i crediti acquisiti su Extraconomy?",
  faqAnswer10:
    "I crediti interni si acquisiscono attraverso le varie attività svolte sulla piattaforma (Iscrizioni, Compra-Vendite e Condivisioni) sottoforma di caschback, premi e bonus, che potranno essere utilizzati di forma complementare negli acquisti all'interno della piattaforma o nelle attività di marketing in base ai termini di utilizzo previsti per ogni profilo utente.",
  faqTitle11:
    "Come posso monitorare e gestire i miei pagamenti, le mie commissioni e i miei bonus ricevuti su Extraconomy? ",
  faqAnswer11:
    "Quando ti registri via Web e via App, ricevi un ufficio virtuale nella tua Area Personale dove potrai tenere traccia di tutte le tue entrate finanziarie dirette e di quelle derivanti dal tuo circolo economico.",
  faqTitle12: "Come ricevo le mie entrate economiche? ",
  faqAnswer12:
    "Le entrate economiche dirette e indirette vengono accreditate sul tuo conto PayPal, che dovrai inserire nella tua Area Personale. Se non hai un conto PayPal, puoi crearne uno gratuitamente su www.paypal.com.",
  SeeTheRegulationsHere: "Vedi qui il regolamento",
  joinTheLaunchpadButton: "Unisciti al Launchpad",
  tax: "Tariffa",
  workWithUs: "Lavora con noi",
  jobOpportunity: "Opportunità lavorativa",
  workWithUsText1:
    "Il sistema Extraconomy offre la possibilità di partecipare allo sviluppo on-line e territoriale attraverso figure chiave (web promoter, ambassador, sales agent, micro-influencer, market affiliates) che potranno beneficiare automaticamente di un sistema provvigionale esclusivo, scalabile e gratificante.",
  freePlan: "Gratuito",
  salesRepresentativeContract: "Contratto di incaricato alla vendita",
  earnCommissionsAndCredits: "Guadagna commissioni e crediti",
  developYourNetwork: "Sviluppa il tuo network",
  financialCareerAwards: "Premi economici alla carriera",
  resources: "Risorse",
  blog: "Blog",
  toTheStore: "ACCEDI AL NEGOZIO",
  revolutionizeWithExtraconomy: "Rivoluziona l'economia con Extraconomy",
  revolutionizeWithExtraconomyDescription:
    "Trasforma le tue spese quotidiane in guadagni e partecipa a un'economia più sostenibile.",
  iWantToStart: "Voglio rivoluzionare l'economia.",
  extrapayBalance: "Saldo Extrapay",
  viewCashback: "Visualizza l'estratto del cashback",
  partnerPlan: "Piano Partner",
  empowerYourBusiness: "Potenziamo il tuo business",
  empowerYourBusinessDescription:
    "Extraconomy è un modello economico che unisce tecnologia, comunità e cooperazione. Abbiamo creato un sistema innovativo che amplifica il potere economico delle persone e sostiene le attività locali.",
  comercialIntegration: "Maggiore integrazione commerciale",
  comercialIntegrationDescription:
    "I nostri strumenti potenziano l'integrazione tra negozi e clienti, basandosi sul modello di geolocalizzazione.",
  powerfulEconomy: "Un'economia più leggera e potente",
  powerfulEconomyDescription:
    "Rafforziamo l'economia locale e aiutiamo i commerci locali a guadagnare maggiore visibilità.",
  instantProfitDescription:
    "Con il circolo economico attivo, inizi a guadagnare denaro dal momento della registrazione e lo ampli con acquisti e segnalazioni.",
  generateCredit: "Genera più credito",
  generateCreditDescription: "Puoi guadagnare denaro mentre fai gli acquisti di tutti i giorni.",
  advantages: "Abbiamo molti vantaggi per te",
  createAnAccountForFree: "Crea il tuo account gratuito e scopri tutti i vantaggi che",
  createAnAccountForFree2: "può offrirti",
  geolocalization: "Geolocalizzazione",
  geolocalizationDescription: "Scopri servizi e aziende locali e rafforza la tua regione.",
  coperativeEconomy: "Economia cooperativa",
  coperativeEconomyDescription:
    "Partecipa attivamente alla costruzione di un futuro più inclusivo.",
  cashback: "Cashback automatico",
  cashbackDescription: "Risparmia e accumula crediti su tutti i tuoi acquisti.",
  pension: "Welfare Integrativo",
  pensionDescription: "Assicura automaticamente il tuo benessere con i risparmi quotidiani.",
  contactUs: "Contattaci su WhatsApp",
  manyAdvantages: "Ci sono tanti vantaggi!",
  monetaryAlternatives:
    "Usa anche la nostra alternativa monetaria, risparmierai di più pagando con ",
  monetaryAlternatives2: "l'utility-token Extracoin!",
  scrollDown: "Continua a esplorare",
  exclusiveResources: "RISORSE ESCLUSIVE",
  whyExtraconomy: "Perché dovresti unirti a Extraconomy?",
  whyExtraconomyDescription:
    "Con le risorse della nostra rete sociale ed economica, puoi potenziare i tuoi guadagni. Scopri tutti i vantaggi di Extraconomy e unisciti a noi.",
  newAcquisition: "Nuovo acquisto",
  newSale: "Nuova vendita",
  localSales: "Vendita locale con ritiro.",
  descExtracredit:
    "Registrati e ottieni Extracredit (consulta le condizioni). Usa il credito per acquistare e/o condividerlo con i tuoi amici.",
  descEconomicNetwork:
    "Crea automaticamente una rete di utenti per acquistare e vendere prodotti e servizi.",
  descTransactionSafety:
    "La nostra piattaforma e robusta e sicura. Puoi acquistare, vendere e trasferire denaro in modo sicuro.",
  descFindEverything:
    "Abbiamo un potente strumento per trovare il prodotto che desideri acquistare in pochi clic.",
  plansAdvantages: "VANTAGGIO IN OGNI PIANO",
  chooseYourPlanTitle: "Scegli il piano che meglio si adatta al tuo profilo.",
  chooseYourPlanDescription:
    "Scopri i nostri piani, scegli quello che meglio si adatta al tuo profilo e unisciti a Extraconomy.",
  noMonthlyFees: "SENZA TASSE MENSILI",
  noMonthlyPaymentsDesc: "Non dovrai effettuare pagamenti mensili.",
  partner: "PARTNER",
  naturalPerson: "Persona fisica",
  salesPlan: "Piano di vendita",
  selectPlan: "SELEZIONA",
  legalOrNaturalPerson: "Persona fisica / Persona giuridica",
  featureShowcase: "Vetrina online",
  featureEconomicNetwork: "Circolo economico",
  featureActiveComission: "Commissione attiva",
  featureEcommerce: "E-commerce personale",
  featureDropshiping: "Dispone di Dropshipping",
  featureOnlineStore: "Negozio online",
  featureGeolocation: "Geolocalizzazione attiva",
  featureEvrythingIncluded: "Tutto incluso",
  mostPopular: "più prescelto",
  doubtsAboutPlans: "Hai dubbi su quale sia il piano migliore?",
  doubtsAboutPlansDesc:
    "Invia un messaggio al nostro servizio clienti e ti aiuteremo a scegliere il piano migliore per il tuo profilo.",
  workWithUsSubtitle: "LAVORA CON NOI",
  workWithUsTitle: "Diventa un Promoter di Extraconomy e moltiplica i tuoi guadagni.",
  workWithUsDescription:
    "Il sistema Extraconomy offre la possibilità di partecipare allo sviluppo online e territoriale attraverso figure chiave (promoter web, ambasciatore, agente commerciale, micro-influencer, affiliati di mercato), che beneficeranno automaticamente di un sistema di commissioni esclusivo, scalabile e gratificante.",
  doYouWantToWork: "Vuoi diventare un Promoter?",
  extracredits: "Ricevi € 500 in crediti extra.",
  moreBonus: "Bonus",
  moreBonusesDescription: "Premi per la carriera finanziaria.",
  recurrence: "Ricorrenza",
  recurrenceDescription: "Sviluppa la tua rete e aumenta la tua ricorrenza di guadagni.",
  comissions: "Commissioni",
  comissionsDescription: "Guadagna commissioni e crediti.",
  clarify: "Chiarisci i tuoi dubbi",
  frequentlyAskedQuestionDescription:
    "Abbiamo raccolto alcune domande e risposte che possono aiutarti nella tua scelta.",
  doubtsDescription: "Invia un messaggio al nostro team.",
  joinUs: "Entra a far parte",
  joinUsDescription: "Registrati e trasformiamo l'economia, insieme.",
  talkWithUs: "Parla con il nostro team.",
  talkWithUsDescription: "Parla con il nostro team commerciale.",
  needHelp: "Hai bisogno di aiuto?",
  call: "Chiama",
  callDescription: "Mettiti in contatto con noi",
  buyInWhatsapp: "Acquista su WhatsApp",
  buyInWhatsappDescription: "Veloce e sicuro",
  assistance: "Assistenza",
  assistanceDescription: "Rispondiamo a tutte le tue domande",
  followUs: "Segui i nostri canali ufficiali",
  shippingMethods: "Modalità di spedizione",
  partners: "Partners",
  discoverExtraconomy: "Scopri Extraconomy",
  rewardsProgram: "Sistema di ricompensa",
  makeMoneyWithUs: "Guadagna denaro",
  sellWithUs: "Vendi con noi",
  becomeAffiliate: "Diventa un promoter",
  ourPolicies: "Le nostre politiche",
  shippingPolicy: "Politica di consegna",
  privacyPolicy: "Politica sulla privacy",
  returnPolicy: "Politica di rimborso",
  returnsAndRefunds: "Resi e sostituzioni",
  partnerPlanDescription: "Per chi inizia a vendere online.",
  sellerPlanDescription: "Per chi vuole incrementare le proprie vendite.",
  managerPlanDescription: "Per chi vuole ampliare il proprio business.",
  planSeller: "SELLER",
};

export default landingDictIT;
