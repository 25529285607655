const extracoinCryptoES = {
  confirmTransctionExtracoin: "Confirmar la transferencia usando Extracoin",
  youDoNotHaveWalletLogged: "No tienes una billetera conectada",
  walletLogout: "Has desconectado tu billetera",
  walletLogin: "Has conectado tu billetera",
  walletLoginFailed: "Error al conectar la billetera",
  sendTransaction: "Enviar transacción",
  destinyWallet: "Monedero del destino",
  EXCQuantity: "Cantidad de Extracoin",
  refreshAccount: "Actualizar cuenta",
};

export default extracoinCryptoES;
