import React from "react";
import chatBlueIcon from "../../../images/landing/chat-alert-blue.svg";
import phoneCopyIcon from "../../../images/landing/phone-copy-blue.svg";
import wttsBlueIcon from "../../../images/landing/wtts-blue.svg";
import LandingAccordionComponent from "../LandingAccordionComponent/LandingAccordionComponent";
import localeText from "../../../../locale";

const LandingFaqComponent = () => {
  const questions = [
    {
      title: localeText("faqTitle2"),
      content: localeText("faqAnswer2"),
      id: 0,
    },
    {
      title: localeText("faqTitle1"),
      content: localeText("faqAnswer1"),
      id: 1,
    },
    {
      title: localeText("faqTitle3"),
      content: localeText("faqAnswer3"),
      id: 2,
    },
    {
      title: localeText("faqTitle8"),
      content: localeText("faqAnswer8"),
      id: 3,
    },
    {
      title: localeText("faqTitle9"),
      content: localeText("faqAnswer9"),
      id: 4,
    },
    {
      title: localeText("faqTitle10"),
      content: localeText("faqAnswer10"),
      id: 5,
    },
  ];

  return (
    <section className=" full-width" style={{ backgroundColor: "#EEF2FF", padding: "120px 0" }}>
      <div className="grid">
        <div className="col-12 md:col-5 flex flex-column gap-7" style={{ color: "#26235E" }}>
          <div className="flex flex-column gap-2">
            <div className="font-medium text-sm">{localeText("clarify")}</div>
            <div className="font-bold text-5xl title">{localeText("frequentlyAskedQuestion")}</div>
            <div className="text-md ">
              {localeText("frequentlyAskedQuestionDescription")}
            </div>
          </div>

          <div className="flex flex-column gap-6">
            <div className="grid gap-5">
              <img className="col-1.5" src={chatBlueIcon} alt="Chat Alert Icon" />
              <div className="flex flex-column gap-1 col">
                <div className="font-semibold" style={{ color: "#1E1B4A" }}>
                  {localeText("doubts")}
                </div>
                <div className="text-sm" style={{ color: "#26235E" }}>
                  {localeText("doubtsDescription")}
                </div>
              </div>
            </div>
            <div className="grid gap-5">
              <img className="col-1.5" src={phoneCopyIcon} alt="Phone Copy Icon" />
              <div className="flex flex-column gap-1 col">
                <div className="font-semibold" style={{ color: "#1E1B4A" }}>
                  {localeText("joinUs")}
                </div>
                <div className="text-sm" style={{ color: "#26235E" }}>
                  {localeText("joinUsDescription")}
                </div>
              </div>
            </div>
            <div className="grid gap-5">
              <img className="col-1.5" src={wttsBlueIcon} alt="Wtts Icon" />
              <div className="flex flex-column gap-1 col">
                <div className="font-semibold" style={{ color: "#1E1B4A" }}>
                  {localeText("talkWithUs")}
                </div>
                <div className="text-sm" style={{ color: "#26235E" }}>
                  {localeText("talkWithUsDescription")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col mt-6 md:mt-0">
          <LandingAccordionComponent questions={questions} />
        </div>
      </div>
    </section>
  );
};

export default LandingFaqComponent;
