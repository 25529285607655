import React, { useEffect, useState } from "react";

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "./styles.css";

import L from "leaflet";
import PinPoint from "../../../../components/Map/pinpoint.png";

import { AdvancedImage } from "@cloudinary/react";

import { fill } from "@cloudinary/url-gen/actions/resize";

import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { cloudinaryInstance } from "../../../../utilities/Cloudinary/cloudinaryInstance";
import ExternalLinkIcon from "../../../../components/ExternalLinkIcon/ExternalLinkIcon";
import { useToastContext } from "../../../../contexts/ToastContext/ToastContext";
import { useUserContext } from "../../../../contexts/UserContext/UserContext";
import localeText from "../../../../locale";
import GetUserInfoService from "../../../../service/GetUserInfoService";
import { on } from "process";

const markerIcon = new L.Icon({
  iconUrl: PinPoint,
  iconSize: [28, 28],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const ChangeMapCenter = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center && center.lat && center.lng) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};

const MapLocationModal = ({ isOpen, onClose, store }) => {
  const [center, setCenter] = useState({ lat: 41.9, lng: 12.5 });
  const ZOOM_LEVEL = 14;

  useEffect(() => {
    setCenter({ lat: store?.latitude, lng: store?.longitude });
  }, []);

  return (
    <Dialog
      header={localeText("locationOfStore")}
      visible={isOpen}
      className="m-2"
      style={{ maxWidth: "800px", width: "100%" }}
      onHide={onClose}
      draggable={false}
    >
      <div className="flex justify-content-center align-items-center">
        <MapContainer center={center} zoom={ZOOM_LEVEL} scrollWheelZoom={false}>
          <ChangeMapCenter center={center} />

          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {store && (
            <Marker
              icon={markerIcon}
              position={[store?.latitude, store?.longitude]}
              style={{ zIndex: 999 }}
            >
              <Popup style={{ maxWidth: "220px" }}>
                <Link
                  to={`/store?user=${store?.user?.username}`}
                  className="font-bold inline-block lg:text-lg text-base text-gray-800 hover:text-green-600"
                >
                  <AdvancedImage
                    cldImg={cloudinaryInstance
                      .image(store?.imagelogoid)
                      .format("webp")
                      .resize(fill())}
                    className="border-1 border-300 w-full"
                    style={{ minWidth: "220px", height: "auto", objectFit: "cover" }}
                  />

                  <p className="text-base m-0">{store?.title}</p>
                  <p className="text-xs m-0 mb-1" style={{ maxWidth: "220px" }}>
                    {store?.user?.address ? `${store?.user?.address},` : ""}{" "}
                    {store?.user?.number ? `${store?.user?.number} -` : ""} {store?.user?.state}{" "}
                    {store?.user?.city ? `${store?.user?.city},` : ""} {store?.user?.country}
                  </p>
                </Link>
                <div>
                  <div className="flex justify-content-start align-items-center gap-2">
                    {store?.facebook && (
                      <ExternalLinkIcon
                        href={`https://facebook.com/${store?.facebook}`}
                        iconClassNames={"pi-facebook"}
                        color="text-black-alpha-70 hover:text-green-600"
                        width="text-base"
                        target="_blank"
                      />
                    )}

                    {store?.instagram && (
                      <ExternalLinkIcon
                        href={`https://instagram.com/${store?.instagram}`}
                        iconClassNames={"pi-instagram"}
                        color="text-black-alpha-70 hover:text-green-600"
                        width="text-base"
                        target="_blank"
                      />
                    )}

                    {store?.tiktok && (
                      <ExternalLinkIcon
                        href={`https://tiktok.com/@${store?.tiktok}`}
                        iconClassNames={"pi-tiktok"}
                        color="text-black-alpha-70 hover:text-green-600"
                        width="text-base"
                        target="_blank"
                      />
                    )}

                    {store?.linkedin && (
                      <ExternalLinkIcon
                        href={`https://linkedin.com/${store?.linkedin}`}
                        iconClassNames={"pi-linkedin"}
                        color="text-black-alpha-70 hover:text-green-600"
                        width="text-base"
                        target="_blank"
                      />
                    )}

                    {store?.twitter && (
                      <ExternalLinkIcon
                        href={`https://twitter.com/${store?.twitter}`}
                        iconClassNames={"pi-twitter"}
                        color="text-black-alpha-70 hover:text-green-600"
                        width="text-base"
                        target="_blank"
                      />
                    )}

                    {store?.youtube && (
                      <ExternalLinkIcon
                        href={`https://youtube.com/${store?.youtube}`}
                        iconClassNames={"pi-youtube"}
                        color="text-black-alpha-70 hover:text-green-600 transition-all animation-duration-400"
                        width="text-base"
                        target="_blank"
                      />
                    )}
                  </div>
                </div>
              </Popup>
            </Marker>
          )}
        </MapContainer>
      </div>
    </Dialog>
  );
};

export default MapLocationModal;
