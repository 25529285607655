import "primeicons/primeicons.css";
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import AppWrapper from "./AppWrapper";
import "./pages/css/layout-blue.css";
import "./pages/css/theme-blue.css";
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import { render } from "react-dom";
import "leaflet/dist/leaflet.css";

// i18n
import { addLocale, locale, updateLocaleOptions } from "primereact/api";
import ITDict from "./utilities/ITDict";
import PTDict from "./utilities/PTDict";
import ESDict from "./utilities/ESDict";

import landingDictEN from "./pages/Landing/landingDictEN";
import landingDictIT from "./pages/Landing/landingDictIT";
import landingDictPT from "./pages/Landing/landingDictPT";
import landingDictES from "./pages/Landing/landingDictES";

import planListDictEN from "./pages/Landing/components/LandingPlanList/planListDictEN";
import planListDictIT from "./pages/Landing/components/LandingPlanList/planListDictIT";
import planListDictPT from "./pages/Landing/components/LandingPlanList/planListDictPT";
import planListDictES from "./pages/Landing/components/LandingPlanList/planListDictES";

import planIntroTextDictEN from "./components/PlanIntroText/planIntroTextDictEN";
import planIntroTextDictIT from "./components/PlanIntroText/planIntroTextDictIT";
import planIntroTextDictPT from "./components/PlanIntroText/planIntroTextDictPT";
import planIntroTextDictES from "./components/PlanIntroText/planIntroTextDictES";

import registerUserDictEN from "./pages/RegisterUser/registerUserDictEN";
import registerUserDictIT from "./pages/RegisterUser/registerUserDictIT";
import registerUserDictPT from "./pages/RegisterUser/registerUserDictPT";
import registerUserDictES from "./pages/RegisterUser/registerUserDictES";

import loginDictEN from "./pages/Login/loginDictEN";
import loginDictIT from "./pages/Login/loginDictIT";
import loginDictPT from "./pages/Login/loginDictPT";
import loginDictES from "./pages/Login/loginDictES";

import userDashboardDictEN from "./pages/UserDashboard/userDashboardDictEN";
import userDashboardDictIT from "./pages/UserDashboard/userDashboardDictIT";
import userDashboardDictPT from "./pages/UserDashboard/userDashboardDictPT";
import userDashboardDictES from "./pages/UserDashboard/userDashboardDictES";

import userDashboardHomeDictEN from "./pages/UserDashboard/components/UserDashboardHome/userDashboardHomeDictEN";
import userDashboardHomeDictIT from "./pages/UserDashboard/components/UserDashboardHome/userDashboardHomeDictIT";
import userDashboardHomeDictPT from "./pages/UserDashboard/components/UserDashboardHome/userDashboardHomeDictPT";
import userDashboardHomeDictES from "./pages/UserDashboard/components/UserDashboardHome/userDashboardHomeDictES";

import networkTableDictEN from "./pages/UserDashboard/components/NetworkTable/networkTableDictEN";
import networkTableDictIT from "./pages/UserDashboard/components/NetworkTable/networkTableDictIT";
import networkTableDictPT from "./pages/UserDashboard/components/NetworkTable/networkTableDictPT";
import networkTableDictES from "./pages/UserDashboard/components/NetworkTable/networkTableDictES";

import editProfileInfoDictEN from "./pages/UserDashboard/components/EditProfileInfo/editProfileInfoDictEN";
import editProfileInfoDictIT from "./pages/UserDashboard/components/EditProfileInfo/editProfileInfoDictIT";
import editProfileInfoDictPT from "./pages/UserDashboard/components/EditProfileInfo/editProfileInfoDictPT";
import editProfileInfoDictES from "./pages/UserDashboard/components/EditProfileInfo/editProfileInfoDictES";

import payPalCheckoutButtonDictEN from "./components/PayPalCheckoutButton/payPalCheckoutButtonDictEN";
import payPalCheckoutButtonDictIT from "./components/PayPalCheckoutButton/payPalCheckoutButtonDictIT";
import payPalCheckoutButtonDictPT from "./components/PayPalCheckoutButton/payPalCheckoutButtonDictPT";
import payPalCheckoutButtonDictES from "./components/PayPalCheckoutButton/paypalCheckoutButtonDictES";

import validateUserInfoDictEN from "./utilities/ValidateUserInfoDict/validateUserInfoDictEN";
import validateUserInfoDictIT from "./utilities/ValidateUserInfoDict/validateUserInfoDictIT";
import validateUserInfoDictPT from "./utilities/ValidateUserInfoDict/validateUserInfoDictPT";
import validateUserInfoDictES from "./utilities/ValidateUserInfoDict/validateUserInfoDictES";

import appTopbarDictEN from "./utilities/AppTopbarDict/appTopbarDictEN";
import appTopbarDictIT from "./utilities/AppTopbarDict/appTopbarDictIT";
import appTopbarDictPT from "./utilities/AppTopbarDict/appTopbarDictPT";
import appTopbarDictES from "./utilities/AppTopbarDict/appTopbarDictES";

import underConstructionDictEN from "./pages/UnderConstruction/underConstructionDictEN";
import underConstructionDictIT from "./pages/UnderConstruction/underConstructionDictIT";
import underConstructionDictPT from "./pages/UnderConstruction/underConstructionDictPT";
import underConstructionDictES from "./pages/UnderConstruction/underConstructionDictES";

import statementTableDictEN from "./pages/UserDashboard/components/StatementTable/statementTableDictEN";
import statementTableDictIT from "./pages/UserDashboard/components/StatementTable/statementTableDictIT";
import statementTableDictPT from "./pages/UserDashboard/components/StatementTable/statementTableDictPT";
import statementTableDictES from "./pages/UserDashboard/components/StatementTable/statementTableDictES";

import couponsDictEN from "./pages/UserDashboard/components/Coupons/couponsDictEN";
import couponsDictIT from "./pages/UserDashboard/components/Coupons/couponsDictIT";
import couponsDictPT from "./pages/UserDashboard/components/Coupons/couponsDictPT";
import couponsDictES from "./pages/UserDashboard/components/Coupons/couponsDictES";

import productsListDictEN from "./pages/UserDashboard/components/ProductsList/productsListDictEN";
import productsListDictIT from "./pages/UserDashboard/components/ProductsList/productsListDictIT";
import productsListDictPT from "./pages/UserDashboard/components/ProductsList/productsListDictPT";
import productsListDictES from "./pages/UserDashboard/components/ProductsList/productsListDictES";

import newProductDictEN from "./pages/UserDashboard/components/NewProduct/newProductDictEN";
import newProductDictIT from "./pages/UserDashboard/components/NewProduct/newProductDictIT";
import newProductDictPT from "./pages/UserDashboard/components/NewProduct/newProductDictPT";
import newProductDictES from "./pages/UserDashboard/components/NewProduct/newProductDictES";

import attributesDictEN from "./pages/UserDashboard/components/Attributes/attributesDictEN";
import attributesDictIT from "./pages/UserDashboard/components/Attributes/attributesDictIT";
import attributesDictPT from "./pages/UserDashboard/components/Attributes/attributesDictPT";
import attributesDictES from "./pages/UserDashboard/components/Attributes/attributesDictES";

import message_EN from "./utilities/message_EN";
import message_IT from "./utilities/message_IT";
import message_PT from "./utilities/message_PT";

import shoppingCartDictEN from "./pages/ShoppingCart/shoppingCartDictEN";
import shoppingCartDictIT from "./pages/ShoppingCart/shoppingCartDictIT";
import shoppingCartDictPT from "./pages/ShoppingCart/shoppingCartDictPT";
import shoppingCartDictES from "./pages/ShoppingCart/shoppingCartDictES";

import ordersDictEN from "./pages/UserDashboard/components/Orders/ordersDictEN";
import ordersDictIT from "./pages/UserDashboard/components/Orders/ordersDictIT";
import ordersDictPT from "./pages/UserDashboard/components/Orders/ordersDictPT";
import ordersDictES from "./pages/UserDashboard/components/Orders/ordersDictES";

import extracoinTransferDictEN from "./pages/UserDashboard/components/ExtracoinTransfer/extracoinTrasnferDictEN";
import extracoinTransferDictIT from "./pages/UserDashboard/components/ExtracoinTransfer/extracoinTrasnferDictIT";
import extracoinTransferDictPT from "./pages/UserDashboard/components/ExtracoinTransfer/extracoinTrasnferDictPT";
import extracoinTransferDictES from "./pages/UserDashboard/components/ExtracoinTransfer/extracoinTransferDictES";

import withdrawIntentDictEN from "./pages/UserDashboard/components/WithdrawIntent/withdrawIntentDictEN";
import withdrawIntentDictIT from "./pages/UserDashboard/components/WithdrawIntent/withdrawIntentDictIT";
import withdrawIntentDictPT from "./pages/UserDashboard/components/WithdrawIntent/withdrawIntentDictPT";
import withdrawIntentDictES from "./pages/UserDashboard/components/WithdrawIntent/withdrawIntentDictES";

import editingProductDictEN from "./pages/UserDashboard/components/EditingProduct/editingProductDictEN";
import editingProductDictIT from "./pages/UserDashboard/components/EditingProduct/editingProductDictIT";
import editingProductDictPT from "./pages/UserDashboard/components/EditingProduct/editingProductDictPT";
import editingProductDictES from "./pages/UserDashboard/components/EditingProduct/editingProductDictES";

import confirmDocumentDictEN from "./components/ConfirmDocument/confirmDocumentDictEN";
import confirmDocumentDictIT from "./components/ConfirmDocument/confirmDocumentDictIT";
import confirmDocumentDictPT from "./components/ConfirmDocument/confirmDocumentDictPT";
import confirmDocumentDictES from "./components/ConfirmDocument/confirmDocumentDictES";

import adminBackofficeDictEN from "./pages/AdminBackoffice/adminBackofficeDictEN";
import adminBackofficeDictIT from "./pages/AdminBackoffice/adminBackofficeDictIT";
import adminBackofficeDictPT from "./pages/AdminBackoffice/adminBackofficeDictPT";
import adminBackofficeDictES from "./pages/AdminBackoffice/adminBackofficeDictES";

import userStoreDictEN from "./pages/UserDashboard/components/UserStore/userStoreDictEN";
import userStoreDictIT from "./pages/UserDashboard/components/UserStore/userStoreDictIT";
import userStoreDictPT from "./pages/UserDashboard/components/UserStore/userStoreDictPT";
import userStoreDictES from "./pages/UserDashboard/components/UserStore/userStoreDictES";
import PopupSuspendedAccounts from "./components/PopupSuspendedAccounts/PopupSuspendedAccounts";

import footerDictEN from "./components/Footer/footerDictEN";
import footerDictIT from "./components/Footer/footerDictIT";
import footerDictPT from "./components/Footer/footerDictPT";
import footerDictES from "./components/Footer/footerDictES";

import shopDictEN from "./pages/Shop/shopDictEN";
import shopDictIT from "./pages/Shop/shopDictIT";
import shopDictPT from "./pages/Shop/shopDictPT";
import shopDictES from "./pages/Shop/shopDictES";

import productInfoDictEN from "./pages/ProductInfo/productInfoDictEN";
import productInfoDictIT from "./pages/ProductInfo/productInfoDictIT";
import productInfoDictPT from "./pages/ProductInfo/productInfoDictPT";
import productInfoDictES from "./pages/ProductInfo/productInfoDictES";
import FavoritesProvider from "./contexts/FavoritesContext/FavoritesContext";

import { extrapayDictEN } from "./pages/UserDashboard/components/ExtraPay/extrapayDictEN";
import { extrapayDictIT } from "./pages/UserDashboard/components/ExtraPay/extrapayDictIT";
import { extrapayDictPT } from "./pages/UserDashboard/components/ExtraPay/extrapayDictPT";
import { extrapayDictES } from "./pages/UserDashboard/components/ExtraPay/extrapayDictES";

import importProductsDictEN from "./pages/UserDashboard/components/ImportProducts/importProductsDictEN";
import importProductsDictIT from "./pages/UserDashboard/components/ImportProducts/importProductsDictIT";
import importProductsDictPT from "./pages/UserDashboard/components/ImportProducts/importProductsDictPT";
import importProductsDictES from "./pages/UserDashboard/components/ImportProducts/importProductsDictES";

import extracoinCryptoEN from "./pages/UserDashboard/components/web3Dashboard/hooks/extracoinCryptoEN";
import extracoinCryptoIT from "./pages/UserDashboard/components/web3Dashboard/hooks/extracoinCryptoIT";
import extracoinCryptoPT from "./pages/UserDashboard/components/web3Dashboard/hooks/extracoinCryptoPT";
import extracoinCryptoES from "./pages/UserDashboard/components/web3Dashboard/hooks/extracoinCryptoES";

import ToastProvider from "./contexts/ToastContext/ToastContext";
import CacheBuster from "./CacheBuster";
import { TutorialProvider } from "./contexts/TutorialContext";
import { UserProvider } from "./contexts/UserContext/UserContext";

if (localStorage.getItem("__extraconomy_locale"))
  locale(localStorage.getItem("__extraconomy_locale"));
else locale(navigator.language.substring(0, 2));

if (!localStorage.getItem("__extraconomy_locale"))
  localStorage.setItem("__extraconomy_locale", navigator.language.substring(0, 2));

// Prime React i18n
addLocale("pt", PTDict);
addLocale("it", ITDict);
addLocale("es", ESDict);

// Landing i18n
addLocale("en", landingDictEN);
updateLocaleOptions(landingDictPT, "pt");
updateLocaleOptions(landingDictIT, "it");
updateLocaleOptions(landingDictES, "es");

// Landing Plan List i18n
updateLocaleOptions(planListDictEN, "en");
updateLocaleOptions(planListDictIT, "it");
updateLocaleOptions(planListDictPT, "pt");
updateLocaleOptions(planListDictES, "es");

// Plan Intro i18n
updateLocaleOptions(planIntroTextDictEN, "en");
updateLocaleOptions(planIntroTextDictIT, "it");
updateLocaleOptions(planIntroTextDictPT, "pt");
updateLocaleOptions(planIntroTextDictES, "es");

// Register User i18n
updateLocaleOptions(registerUserDictEN, "en");
updateLocaleOptions(registerUserDictIT, "it");
updateLocaleOptions(registerUserDictPT, "pt");
updateLocaleOptions(registerUserDictES, "es");

// Login i18n
updateLocaleOptions(loginDictEN, "en");
updateLocaleOptions(loginDictIT, "it");
updateLocaleOptions(loginDictPT, "pt");
updateLocaleOptions(loginDictES, "es");

// User Dashboard i18n
updateLocaleOptions(userDashboardDictEN, "en");
updateLocaleOptions(userDashboardDictIT, "it");
updateLocaleOptions(userDashboardDictPT, "pt");
updateLocaleOptions(userDashboardDictES, "es");

// User Dashboard Home i18n
updateLocaleOptions(userDashboardHomeDictEN, "en");
updateLocaleOptions(userDashboardHomeDictIT, "it");
updateLocaleOptions(userDashboardHomeDictPT, "pt");
updateLocaleOptions(userDashboardHomeDictES, "es");

// Network Table i18n
updateLocaleOptions(networkTableDictEN, "en");
updateLocaleOptions(networkTableDictIT, "it");
updateLocaleOptions(networkTableDictPT, "pt");
updateLocaleOptions(networkTableDictES, "es");

// Edit Profile Info i18n
updateLocaleOptions(editProfileInfoDictEN, "en");
updateLocaleOptions(editProfileInfoDictIT, "it");
updateLocaleOptions(editProfileInfoDictPT, "pt");
updateLocaleOptions(editProfileInfoDictES, "es");

// PayPal Checkout Button i18n
updateLocaleOptions(payPalCheckoutButtonDictEN, "en");
updateLocaleOptions(payPalCheckoutButtonDictIT, "it");
updateLocaleOptions(payPalCheckoutButtonDictPT, "pt");
updateLocaleOptions(payPalCheckoutButtonDictES, "es");

// Validate User Info i18n
updateLocaleOptions(validateUserInfoDictEN, "en");
updateLocaleOptions(validateUserInfoDictIT, "it");
updateLocaleOptions(validateUserInfoDictPT, "pt");
updateLocaleOptions(validateUserInfoDictES, "es");

// App Topbar i18n
updateLocaleOptions(appTopbarDictEN, "en");
updateLocaleOptions(appTopbarDictIT, "it");
updateLocaleOptions(appTopbarDictPT, "pt");
updateLocaleOptions(appTopbarDictES, "es");

// Under Construction i18n
updateLocaleOptions(underConstructionDictEN, "en");
updateLocaleOptions(underConstructionDictIT, "it");
updateLocaleOptions(underConstructionDictPT, "pt");
updateLocaleOptions(underConstructionDictES, "es");

// Statement Table i18n
updateLocaleOptions(statementTableDictEN, "en");
updateLocaleOptions(statementTableDictIT, "it");
updateLocaleOptions(statementTableDictPT, "pt");
updateLocaleOptions(statementTableDictES, "es");

// Coupons i18n
updateLocaleOptions(couponsDictEN, "en");
updateLocaleOptions(couponsDictIT, "it");
updateLocaleOptions(couponsDictPT, "pt");
updateLocaleOptions(couponsDictES, "es");

// Products List i18n
updateLocaleOptions(productsListDictEN, "en");
updateLocaleOptions(productsListDictIT, "it");
updateLocaleOptions(productsListDictPT, "pt");
updateLocaleOptions(productsListDictES, "es");

// New product List i18n
updateLocaleOptions(newProductDictEN, "en");
updateLocaleOptions(newProductDictIT, "it");
updateLocaleOptions(newProductDictPT, "pt");
updateLocaleOptions(newProductDictES, "es");

// Attributes List i18n
updateLocaleOptions(attributesDictEN, "en");
updateLocaleOptions(attributesDictIT, "it");
updateLocaleOptions(attributesDictPT, "pt");
updateLocaleOptions(attributesDictES, "es");

// Error Messages i18n
updateLocaleOptions(message_EN, "en");
updateLocaleOptions(message_IT, "it");
updateLocaleOptions(message_PT, "pt");

// Shopping cart i18n
updateLocaleOptions(shoppingCartDictEN, "en");
updateLocaleOptions(shoppingCartDictIT, "it");
updateLocaleOptions(shoppingCartDictPT, "pt");
updateLocaleOptions(shoppingCartDictES, "es");

// Orders i18n
updateLocaleOptions(ordersDictEN, "en");
updateLocaleOptions(ordersDictIT, "it");
updateLocaleOptions(ordersDictPT, "pt");
updateLocaleOptions(ordersDictES, "es");

// Extracoin transfer i18n
updateLocaleOptions(extracoinTransferDictEN, "en");
updateLocaleOptions(extracoinTransferDictIT, "it");
updateLocaleOptions(extracoinTransferDictPT, "pt");
updateLocaleOptions(extracoinTransferDictES, "es");

// Withdraw transfer i18n
updateLocaleOptions(withdrawIntentDictEN, "en");
updateLocaleOptions(withdrawIntentDictIT, "it");
updateLocaleOptions(withdrawIntentDictPT, "pt");
updateLocaleOptions(withdrawIntentDictES, "es");

// Editing product i18n
updateLocaleOptions(editingProductDictEN, "en");
updateLocaleOptions(editingProductDictIT, "it");
updateLocaleOptions(editingProductDictPT, "pt");
updateLocaleOptions(editingProductDictES, "es");

// Confirm document i18n
updateLocaleOptions(confirmDocumentDictEN, "en");
updateLocaleOptions(confirmDocumentDictIT, "it");
updateLocaleOptions(confirmDocumentDictPT, "pt");
updateLocaleOptions(confirmDocumentDictES, "es");

// Admin backoffice i18n
updateLocaleOptions(adminBackofficeDictEN, "en");
updateLocaleOptions(adminBackofficeDictIT, "it");
updateLocaleOptions(adminBackofficeDictPT, "pt");
updateLocaleOptions(adminBackofficeDictES, "es");

// Admin backoffice i18n
updateLocaleOptions(userStoreDictEN, "en");
updateLocaleOptions(userStoreDictIT, "it");
updateLocaleOptions(userStoreDictPT, "pt");
updateLocaleOptions(userStoreDictES, "es");

// Footer i18n
updateLocaleOptions(footerDictEN, "en");
updateLocaleOptions(footerDictIT, "it");
updateLocaleOptions(footerDictPT, "pt");
updateLocaleOptions(footerDictES, "es");

// Shop i18n
updateLocaleOptions(shopDictEN, "en");
updateLocaleOptions(shopDictIT, "it");
updateLocaleOptions(shopDictPT, "pt");
updateLocaleOptions(shopDictES, "es");

// ProductInfo i18n
updateLocaleOptions(productInfoDictEN, "en");
updateLocaleOptions(productInfoDictIT, "it");
updateLocaleOptions(productInfoDictPT, "pt");
updateLocaleOptions(productInfoDictES, "es");

// Extrapay i18n
updateLocaleOptions(extrapayDictEN, "en");
updateLocaleOptions(extrapayDictIT, "it");
updateLocaleOptions(extrapayDictPT, "pt");
updateLocaleOptions(extrapayDictES, "es");

// Import products i18n
updateLocaleOptions(importProductsDictEN, "en");
updateLocaleOptions(importProductsDictIT, "it");
updateLocaleOptions(importProductsDictPT, "pt");
updateLocaleOptions(importProductsDictES, "es");

// Import extracoin crypto i18n
updateLocaleOptions(extracoinCryptoEN, "en");
updateLocaleOptions(extracoinCryptoIT, "it");
updateLocaleOptions(extracoinCryptoPT, "pt");
updateLocaleOptions(extracoinCryptoES, "es");

const container = document.getElementById("root");

render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}>
          <PayPalScriptProvider
            options={{
              clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
              components: "card-fields,buttons",
              currency: process.env.REACT_APP_BASE_PAYPAL_CURRENCY,
              /* dataClientToken: "CNKDKSA7YYQSG", */
            }}
          >
            <PopupSuspendedAccounts />
            <BrowserRouter>
              <ToastProvider>
                <UserProvider>
                  <TutorialProvider>
                    <FavoritesProvider>
                      <AppWrapper />
                    </FavoritesProvider>
                  </TutorialProvider>
                </UserProvider>
              </ToastProvider>
            </BrowserRouter>
          </PayPalScriptProvider>
        </GoogleReCaptchaProvider>
      );
    }}
  </CacheBuster>,
  container
);
